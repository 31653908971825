import telerehabSketch from "../../media/landing/telerehabSketch.png";
import posterDCA from "../../media/landing/posterDCA.png";
import posterNeglect from "../../media/landing/posterNeglect.png";

export const researchData =
{
	"goal": "“Nuestro compromiso es ofrecer una herramienta validada científicamente, que haya demostrado su utilidad ayudando a personas que lo necesitan”",
	"image": telerehabSketch,
	"paragraph": `Con la colaboración del laboratorio de Neurociencia de Granada NeuroLab y la Universidad de Granada se han llevado a cabo diversos estudios de validación de la herramienta que has sido presentados en congresos Nacionales e Internacionales de Neurociencia.  \n
    Esta herramienta se utiliza actualmente en el centro de rehabilitación de AGREDACE en Granada, bajo la supervisión de un equipo de Neuropsicólogos, con pacientes que han sufrido una lesión cerebral para mejorar sus procesos cognitivos y así su calidad de vida, aportando muy buenos resultados tanto a nivel cualitativo como cuantitativo en los pacientes incluso tras la primera aplicación.`,
	"studies": [
		{
			"title": "Estudio RTC",
			"description": "Estudio experimental con grupo de control Re-Cognition vs rehabilitación tradicional, para la memoria y atención en DCA.",
			"image": posterDCA,
			"url": "https://drive.google.com/file/d/1kuUkG9VCeosVz1EzEBOB42nfr2kMGTbf/"
		},
		{
			"title": "Estudio de caso",
			"description": "Estudio de caso único con paciente heminegligente, impulsiva y con alteración en memoria de trabajo.",
			"image": posterNeglect,
			"url": "https://drive.google.com/file/d/1hQjUzgn_7dzbJLlGoC1cgVIOYd2Rm1G6/view?usp=drive_link"
		},
	],
}