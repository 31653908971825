import { Box, Paper, Typography } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../contexts/User.context";
import telerehabSketch from "../../media/landing/telerehabSketch.png";
import { translate } from "../../text/translator";
import LicenseButton from '../buying/LicenseButton';
import { Role, roleToText } from "../login/PremiumUser";

function RestrictedPage() {

	const user = useContext(UserContext);

	return (
		<>
			<Typography variant="h3" gutterBottom>
				{translate("[restricted_title]")}
			</Typography>

			<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">

				<Paper elevation={3} style={{ maxWidth: 600, width: "100%" }}>
					<Box display="flex" alignItems="center" flexDirection="column" paddingBottom={2} >
						<img src={telerehabSketch} alt="" style={{ maxWidth: 500, width: "100%" }} />

						<Typography variant="body1" paddingX={2} paddingBottom={2} >
							{translate("[restricted_body]")}
						</Typography>

						{!!user ?
							<>
								<Typography variant="h6" gutterBottom paddingX={2}>
									{`${translate("[restricted_license]")} ${translate(roleToText(user.role))}`}
								</Typography>
								<LicenseButton hasLicense={user.role !== Role.None} />
							</>
							:
							<>
								<Typography variant="h6" gutterBottom paddingX={2}>
									{translate("[restricted_license_none]")}
								</Typography>
								<LicenseButton hasLicense={false} />
							</>
						}
					</Box>
				</Paper>
			</Box>
		</>
	);
}

export default RestrictedPage;
