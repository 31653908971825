
export interface UserReviewData {
  name: string,
  title: string,
  review: string,
}

export const userReviewsTitle = "Opiniones de usuarios";

export const userReviewsData: UserReviewData[] = [
  {
    "name": "Frank Martín",
    "title": "Fisioterapeuta",
    "review": "La RV es una tecnología innovadora que ayuda al usuario a simular experiencias en mundos diferentes a los nuestros",
  },
  {
    "name": "Celia Mayoral",
    "title": "Psicóloga",
    "review": "Con la ayuda de la tecnología RV los investigadores podemos diseñar experimentos altamente realistas y ecológicos.",
  },
  {
    "name": "María Gomez",
    "title": "Usuaria",
    "review": "Con este sistema he podido reaprender ciertas cosas que me costaban tras haber sufrido un ICTUS.",
  },
  {
    "name": "Julio Estaban",
    "title": "Ingeniero Biomédico",
    "review": "Las realidades extendidas serán una técnica medica efectiva y real que impulsará la medicina",
  },
  {
    "name": "Fernanda García",
    "title": "Usuaria",
    "review": "Poder continuar la rehabilitación en casa ha sido la clave en mi recuperación, ojalá los hubiera conocido antes.",
  },
  {
    "name": "Javier López",
    "title": "Usuario",
    "review": "Es como transportarte a otro sitio, increíblemente real, te mantiene alerta y motivado y con ganas de seguir rehabilitándome ",
  },
  {
    "name": "Carlos Cari",
    "title": "Investigador",
    "review": "Este sistema es altamente flexible y es capaz de monitorizar absolutamente todos los datos de manera precisa de nuestros participantes",
  }
];