import { Box, SvgIcon, Typography, useTheme } from "@mui/material";
import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis, ZAxis } from "recharts";
import { SymbolType } from "recharts/types/util/types";
import { translate } from "../../../text/translator";
import { DishwasherData } from "../../DishwasherData";
import { renderBlackLegend } from "../legend";
import { ReactComponent as DirectionIcon } from '../../../media/direction.svg';


interface DishwasherCoordinatesGraphData {
	data: DishwasherData
	topTray: boolean
}

function DishwasherCoordinatesGraph(props: DishwasherCoordinatesGraphData) {

	const mugs = props.data.mugs.items;
	const forks = props.data.forks.items;
	const dishes = props.data.dishes.items;

	const isCorrect = (entry: any) => entry.wellOriented && (entry.hasBeenGrabbed === undefined || !!entry.hasBeenGrabbed);
	const isIncorrect = (entry: any) => !entry.wellOriented && (entry.hasBeenGrabbed === undefined || !!entry.hasBeenGrabbed);
	const isCorrectUngrabbed = (entry: any) => entry.wellOriented && (entry.hasBeenGrabbed !== undefined && !entry.hasBeenGrabbed);
	const isIncorrectUngrabbed = (entry: any) => !entry.wellOriented && (entry.hasBeenGrabbed !== undefined && !entry.hasBeenGrabbed);

	const mugShape: SymbolType = "square";
	const forkShape: SymbolType = "diamond";
	const dishShape: SymbolType = "circle";

	const mugTranslationKey = "mug";
	const forkTranslationKey = "fork";
	const dishTranslationKey = "plate";

	const colors = useTheme().palette;
	const correctColor = colors.primary.dark;
	const incorrectColor = colors.secondary.main;
	const correctUngrabbedColor = colors.primary.light;
	const incorrectUngrabbedColor = colors.secondary.light;

	const userRotation = props.data.laterality === 0 ? "rotate(90deg)" : "rotate(0deg)";

	return (<>
		<Typography>
			<strong>
				{translate(props.topTray ? "[graphs_dishwasher_distribution_top]" : "[graphs_dishwasher_distribution_bottom]")}
			</strong>
		</Typography>

		<Box flexDirection="row" display="flex" justifyContent="space-between">
			<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" textAlign="center">
				<SvgIcon component={DirectionIcon}
					color="primary"
					viewBox="0 0 64 64"
					style={{
						color: colors.primary.main,
						transform: userRotation,
						transformOrigin: "center",
						fontSize: "4rem"
					}} />
				<Typography variant="caption">
					{translate("[graphs_dishwasher_userdirection]")}
				</Typography>
			</Box>

			{props.topTray &&
				<ResponsiveContainer  height={300}>
					<ScatterChart>
						<XAxis hide={true} domain={[-0.3, 0.3]} type="number" dataKey="x" />
						<YAxis hide={true} domain={[-0.3, 0.3]} type="number" dataKey="y" />
						<ZAxis range={[200, 200]} />
						<CartesianGrid />
						<Legend formatter={renderBlackLegend} />

						<Scatter name={translate(`[graphs_dishwasher_${mugTranslationKey}_hit]`)}
							shape={mugShape}
							legendType={mugShape}
							data={mugs.filter(isCorrect).map((entry) => entry.coordinates)}
							fill={correctColor} />
						<Scatter name={translate(`[graphs_dishwasher_${mugTranslationKey}_hit_ungrabbed]`)}
							shape={mugShape}
							legendType={mugShape}
							data={mugs.filter(isCorrectUngrabbed).map((entry) => entry.coordinates)}
							fill={correctUngrabbedColor} />
						<Scatter name={translate(`[graphs_dishwasher_${mugTranslationKey}_miss]`)}
							shape={mugShape}
							legendType={mugShape}
							data={mugs.filter(isIncorrect).map((entry) => entry.coordinates)}
							fill={incorrectColor} />
						<Scatter name={translate(`[graphs_dishwasher_${mugTranslationKey}_miss_ungrabbed]`)}
							shape={mugShape}
							legendType={mugShape}
							data={mugs.filter(isIncorrectUngrabbed).map((entry) => entry.coordinates)}
							fill={incorrectUngrabbedColor} />
					</ScatterChart>
				</ResponsiveContainer>
			}
			{!props.topTray &&
				<ResponsiveContainer height={350}>
					<ScatterChart>
						<XAxis hide={true} domain={[-0.3, 0.3]} type="number" dataKey="x" />
						<YAxis hide={true} domain={[-0.3, 0.3]} type="number" dataKey="y" />
						<ZAxis range={[200, 200]} />
						<CartesianGrid />
						<Legend formatter={renderBlackLegend} />

						<Scatter name={translate(`[graphs_dishwasher_${forkTranslationKey}_hit]`)}
							shape={forkShape}
							legendType={forkShape}
							data={forks.filter(isCorrect).map((entry) => entry.coordinates)}
							fill={correctColor} />
						<Scatter name={translate(`[graphs_dishwasher_${forkTranslationKey}_hit_ungrabbed]`)}
							shape={forkShape}
							legendType={forkShape}
							data={forks.filter(isCorrectUngrabbed).map((entry) => entry.coordinates)}
							fill={correctUngrabbedColor} />

						<Scatter name={translate(`[graphs_dishwasher_${dishTranslationKey}_hit]`)}
							shape={dishShape}
							legendType={dishShape}
							data={dishes.filter(isCorrect).map((entry) => entry.coordinates)}
							fill={correctColor} />
						<Scatter name={translate(`[graphs_dishwasher_${dishTranslationKey}_hit_ungrabbed]`)}
							shape={dishShape}
							legendType={dishShape}
							data={dishes.filter(isCorrectUngrabbed).map((entry) => entry.coordinates)}
							fill={correctUngrabbedColor} />

						<Scatter name={translate(`[graphs_dishwasher_${forkTranslationKey}_miss]`)}
							shape={forkShape}
							legendType={forkShape}
							data={forks.filter(isIncorrect).map((entry) => entry.coordinates)}
							fill={incorrectColor} />
						<Scatter name={translate(`[graphs_dishwasher_${forkTranslationKey}_miss_ungrabbed]`)}
							shape={forkShape}
							legendType={forkShape}
							data={forks.filter(isIncorrectUngrabbed).map((entry) => entry.coordinates)}
							fill={incorrectUngrabbedColor} />


						<Scatter name={translate(`[graphs_dishwasher_${dishTranslationKey}_miss]`)}
							shape={dishShape}
							legendType={dishShape}
							data={dishes.filter(isIncorrect).map((entry) => entry.coordinates)}
							fill={incorrectColor} />
						<Scatter name={translate(`[graphs_dishwasher_${dishTranslationKey}_miss_ungrabbed]`)}
							shape={dishShape}
							legendType={dishShape}
							data={dishes.filter(isIncorrectUngrabbed).map((entry) => entry.coordinates)}
							fill={incorrectUngrabbedColor} />

					</ScatterChart>
				</ResponsiveContainer>
			}
		</Box>
	</>);
}

export default DishwasherCoordinatesGraph;