export const pricingData =
{

	patient:
		{
			title: "Paciente",
			description: "Para **pacientes** que solamente quieren usar los ejercicios en Realidad Virtual.",
			price: "**10€** al mes   \no **100€** al año",
			features:
				[
					"Acceso a **todos los procesos** en realidad virtual.",
					"Configuración completa de las preferencias de **accesibilidad** del paciente.",
				],
		},
		therapist:{
			title: "Terapeuta",
			description: "Para **terapeutas** o pequeños centros que deseen usar Re-cognition con sus pacientes.",
			price: "**30€** al mes  \no **300€** al año",
			features:
				[
					"Todas las características de la licencia de **Paciente**.",
					"Controla y observa **en tiempo real** los ejercicios de tus pacientes.",
					"Accede a los **datos de las sesiones** de tus pacientes.",
					"Pacientes **ilimitados**.",
				],
		},
		centre:{
			title: "Centro",
			description: "Para centros de rehabilitación o investigación con **varios terapeutas** trabajando a la vez.",
			price: "**80€** al mes  \no **800€** al año",
			features:
				[
					"Todas las características de la licencia de **Terapeuta**.",
					"**Tres terapeutas** trabajando de forma concurrente.",
				],
		},
		custom:{
			title: "A medida",
			description: "¿No encuentras una licencia que se ajuste a tus necesidades? ¡Escríbenos!",
			price: "**Negociable**",
			features:
				[
					"Cursos de **formación** para tu plantilla.",
					"Ejercicios **a medida** para investigación.",
					"Acceso a **decenas de terapeutas** o desde varios centros.",
					"**Más datos** y más control sobre ellos.",
				],
		},
}