import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { translate } from '../../../text/translator';
import ModifierControl from './ModifierControl';

interface ToggleGroupControlProps {
	title?: string,
	description?: string,
	items: string[],
	value?: number,
	onValueChanged: (value: number) => void
}

function ToggleGroupControl(props: ToggleGroupControlProps) {

	const items = props.items;
	const value = props.value ?? 0;

	const onValueChanged = props.onValueChanged;
	const [prevValue, setPrevValue] = useState<number | null>(null);

	useEffect(() => {
		if (prevValue !== value
			&& value != null) {
			onValueChanged(value);
			setPrevValue(value);
		}
	}, [value, prevValue, onValueChanged]);


	const handleOnChanged = (
		event: MouseEvent<HTMLElement>,
		index: number,
	) => {
		if (index == null) {
			onValueChanged(value);
		}
		else if (index !== value) {
			onValueChanged(index);
		}
	};

	return (
		<ModifierControl
			title={props.title}
			description={props.description}>
			<ToggleButtonGroup
				value={value}
				exclusive
				onChange={handleOnChanged}
				color="primary"
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "left",
				}}
			>
				{items.map((value: string, index: number) => {
					return (
						<ToggleButton value={index} aria-label={value} key={index}>
								<Typography>
									{translate(value)}
								</Typography>
						</ToggleButton>);
				})};
			</ToggleButtonGroup>

		</ModifierControl>
	)
}


export default ToggleGroupControl;