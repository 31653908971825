import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { CartesianGrid, ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { translate } from "../../text/translator";
import { GonogoTryData } from "../GonogoData";
import { renderBlackLegend } from "./legend";

export const CustomizedTooltip = (props: any) => {
	const { payload } = props;
	if (!payload?.[0]?.value) {
		return <></>;
	}

	const data: GonogoTryData = payload[0].payload;
	return (
		<div className="custom-tooltip">
			<p className="label">⏱ Tiempo de reacción: {data.reactionTime.toFixed(3)}</p>
			<p className="intro">✋🏼 Mano dominante: {data.handedness}</p>
			<p className="desc"> 📦 Objeto: {data.itemData.color} {data.itemData.size} {data.itemData.name} </p>
		</div>
	);
};

interface GonogoReactionGraphData {
	tries: GonogoTryData[],
}

function GoNogoReactionGraph(props: GonogoReactionGraphData) {
	const colors = useTheme().palette;

	const commisionErrors = props.tries
		.map((data, index) => ({ ...data, index: index }))
		.filter(t => t.isCommisionError);

	const omissionErrors = props.tries
		.map((data, index) => ({ ...data, index: index }))
		.filter(t => t.isOmissionError);

	return (<>
		<Typography><strong>{translate("[value_reactiontimes]")}</strong></Typography>
		<ResponsiveContainer height={500}>
			<ComposedChart data={props.tries}>
				<XAxis dataKey="name" />
				<YAxis />
				<Legend formatter={renderBlackLegend}
					payload={[{ value: translate("[value_reactiontime]"), type: "line", id: "ID01", color: colors.primary.main },
					{ value: translate("[graphs_gonogo_commision_error]"), type: "plainline", id: "ID02", color: colors.error.main, payload: { strokeDasharray: "3 3" } },
					{ value: translate("[graphs_gonogo_omission_error]"), type: "plainline", id: "ID03", color: colors.error.main, payload: { strokeDasharray: "0" } }]}
				/>
				<CartesianGrid stroke="#f5f5f5" />
				<Line connectNulls
					type="linear"
					strokeWidth="4"
					dataKey="reactionTime"
					stroke={colors.primary.main} />

				{commisionErrors.map((data, index) => (
					<ReferenceLine
						key={`commision_${index}`}
						x={data.index}
						stroke={colors.error.main}
						strokeDasharray="3 3"
						strokeWidth={2}
						isFront />
				))}

				{omissionErrors.map((data, index) => (
					<ReferenceLine
						key={`ommision_${index}`}
						x={data.index}
						stroke={colors.error.main}
						strokeWidth={2}
						isFront />
				))}

				<Tooltip content={<CustomizedTooltip />} />
			</ComposedChart>
		</ResponsiveContainer>
	</>);
}

export default GoNogoReactionGraph;