import { Box, Grid, Typography } from '@mui/material';
import { advantagesData, advantagesTitle } from "./advantagesData"

function Advantages() {
	return (
		<>
			<Typography variant="h2" gutterBottom>
				{advantagesTitle}
			</Typography>
			<Grid container spacing={2}>
				{advantagesData.map((entry, index) => {
					return (
						<Grid item xs={12} sm={6} lg={3} key={index}>
							<Box display="flex" alignContent="center" flexDirection="column">
								<img src={entry.image} alt="" width={100} />
								<Typography variant="h5" gutterBottom>
									{entry.title}
								</Typography>
								<Typography variant="body1" gutterBottom>
									{entry.description}
								</Typography>
							</Box>
						</Grid>)
				})}
			</Grid>
		</>
	);
}

export default Advantages;