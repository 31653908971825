import InfoIcon from '@mui/icons-material/Info';
import { ClickAwayListener, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { translate } from '../../../text/translator';

interface ModifierControlProps {
	title?: string;
	description?: string
	children?: React.ReactNode;
}

interface ModifiersToolTipProps {
	description: string
}

interface ToolTipProps {
	content: React.ReactNode,
	children?: React.ReactNode
}

export function TooltipCustom(props: ToolTipProps) {
	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<Tooltip
				PopperProps={{
					disablePortal: true,
				}}
				onClose={handleTooltipClose}
				open={open}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				title={props.content}
				onClick={handleTooltipOpen}
			>
				<div>
					{props.children}
				</div>
			</Tooltip>
		</ClickAwayListener>
	);
};


export function ModifiersToolTip(props: ModifiersToolTipProps) {
	return (
		<TooltipCustom
			content={translate(props.description)}>
			<IconButton aria-label="extrainformation" size="small">
				<InfoIcon fontSize="small" />
			</IconButton>
		</TooltipCustom>
	);
};

export function ModifierControl(props: ModifierControlProps) {
	return (
		<Box marginBottom={2} >
			<Box marginBottom={1} display="flex" flexDirection="row">
				{!!props.title &&
					<Typography>
						{translate(props.title)}
					</Typography>
				}
				{!!props.description &&
					<ModifiersToolTip description={props.description} />}
			</Box>
			{props.children}
		</Box>
	)
}


export default ModifierControl;