import { useState, useMemo } from "react";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box'
import { MultiObjectData } from "../MultiObjectData";
import { renderBlackLegend } from "./legend";
import { Typography } from "@mui/material";
import { translate } from "../../text/translator";

interface ReactionTimeGraphData {
	data: any;
	xDataKey: string;
	onClick: any;
	totalBlocks: number;
	isNumber: boolean;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

export const CustomizedTooltip = (props: any) => {

	const { payload } = props;
	if (payload === undefined
		|| !payload
		|| payload[0] === undefined) {
		return <></>;
	}

	const data: any = payload[0].payload;

	return (
		<div className="custom-tooltip">
			{!!data.blockTitle &&
				<p className="label"><strong>
					{translate("[graph_multiobject_block_name]")}: {translate(data.blockTitle)}
				</strong>
				</p>
			}
			<p className="label">🏃‍ {translate("[value_decissiontime]")}: {data.decisionTime.toFixed(3)}</p>
			<p className="label">⏱ {translate("[value_reactiontime]")}: {data.reactionTime.toFixed(3)}</p>

			<p className="intro">✋🏼 {translate("[value_dominanthand]")}: {
				data.handedness.left === data.handedness.right ? translate("[value_equal]") :
					(data.handedness.left > data.handedness.right ?
						translate("[value_left]") :
						translate("[value_right]"))
			}</p>
			<p className="desc">❌ {translate("[graph_multiobject_errors]")}: {data.errors} </p>
			<p className="desc">🔍 {translate("[graph_multiobject_clues]")}: {data.usedClues} </p>

		</div>
	);
};

function ReactionTimeGraph(props: ReactionTimeGraphData) {
	const [data, setData] = useState([]);
	useMemo(() => {
		const a = props.data.map((block: MultiObjectData) => {
			return {
				...block,
				decisionTime: (block.decisionTime / block.count),
				reactionTime: (block.reactionTime / block.count),
				reactionMinusDecissionTime: (block.reactionTime - block.decisionTime) / block.count,
			};
		});
		setData(a);
	}, [props.data]);
	const colors = useTheme().palette;

	return (
		<ResponsiveContainer width="100%" height="100%">
			<ComposedChart data={data}>
				<XAxis dataKey={props.xDataKey} domain={[0, props.totalBlocks - 1]}
					type={props.isNumber ? "number" : "category"}
					allowDecimals={false} tickCount={props.totalBlocks} />
				<YAxis dataKey="reactionTime" domain={[0, (dataMax: number) => (dataMax.toFixed(2))]}
				/>
				<Tooltip content={<CustomizedTooltip />} />
				<Legend formatter={renderBlackLegend}/>
				<CartesianGrid stroke="#f5f5f5" />
				<Bar name={translate("[value_decissiontime]")} onClick={props.onClick} stackId="reactionTime" dataKey="decisionTime" barSize={15} fill={colors.primary.light} />
				<Bar name={translate("[value_reactiontime]")} onClick={props.onClick} stackId="reactionTime" dataKey="reactionMinusDecissionTime" barSize={15} fill={colors.primary.dark} />
				<Line name={translate("[value_clue]")} type="linear" strokeWidth={3} dataKey="usedClues" stroke={colors.secondary.main} />
				<Line name={translate("[value_error]")} type="linear" strokeWidth={3} dataKey="errors" stroke={colors.error.light} />
			</ComposedChart>
		</ResponsiveContainer>
	)
}

interface ReactionTimeData {
	data: any
	totalBlocks: number;
}

function ReactionTime(props: ReactionTimeData) {
	const [detailChart, setDetailChart] = useState(null);

	function handleChartClick(index: number) {
		const subChartData = props.data[index].rawData;
		setDetailChart(subChartData);
	}

	return (
		<>
			<Typography><strong>{translate("[graph_multiobject_blockresults]")}</strong></Typography>
			<ResponsiveContainer height={500}>
				<ReactionTimeGraph
					data={props.data}
					totalBlocks={props.totalBlocks}
					onClick={(data: any) => handleChartClick(data.index)}
					xDataKey="hierarchy"
					isNumber={true} />
			</ResponsiveContainer>
			<Modal
				open={!!detailChart}
				onClose={() => setDetailChart(null)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<Box sx={style}>
					<Typography><strong>{translate("[graph_multiobject_trialresults]")}</strong></Typography>
					<ResponsiveContainer height={500}>
						<ReactionTimeGraph
							data={detailChart}
							totalBlocks={-1}
							onClick={null}
							xDataKey="name"
							isNumber={false}		
						/>
					</ResponsiveContainer>
				</Box>
			</Modal>
		</>
	);
}

export default ReactionTime;
