import { Typography } from "@mui/material";
import { translate } from "../../text/translator";
import ContactForm from "../landing/ContactForm";
import Newsletter from "../landing/Newsletter";

function Contact() {
	return (
		<>
			<Typography variant="h3" gutterBottom>
				{translate("[contact_title]")}
			</Typography>
			<ContactForm />
			<br/>
			<Newsletter />
		</>
	);
}

export default Contact;