import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import { Box, Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import ProcessPill from "../../components/atoms/ProcessPill";
import { RemoteControlState } from "../../contexts/RemoteControlState";
import { translate } from '../../text/translator';
import Avatar from "../users/Avatar";
import { TooltipCustom } from './controls/ModifierControl';
import { ModifiersText } from '../session/Modifiers';

interface RemoteControlBarProps {
	state: RemoteControlState;
	onPatientConfig: () => void;
	onDisconnect: () => void;
	onRouteChange: (route: string) => void;
}

interface StyledPaperProps {
	title: string;
	highlight: boolean;
	refreshIcon?: React.ReactNode;
	children: React.ReactNode;
}

const StyledPaper = (props: StyledPaperProps) => {

	return (
		<Paper elevation={props.highlight ? 10 : 0}
			style={{ height: '100%', padding: '10px', display: 'flex', flexDirection: 'column' }}>
			<Box style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
				<Typography variant="caption">
					{props.highlight ? <strong>{props.title}</strong> : props.title}
				</Typography>
				{props.refreshIcon}
			</Box>
			<Box style={{ width: "100%", height: "100%", justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
				{props.children}
			</Box>
		</Paper>
	);
};

function RemoteControlBar(props: RemoteControlBarProps) {
	const state = props.state;
	const location = useLocation();
	const curentPath = location.pathname;

	return (
		<Box>
			<Grid container spacing={0.5} >

				<Grid item xs={12} sm={6} md={7} xl={5} style={{ height: '120px' }}>
					<StyledPaper title={`1. ${translate("[remotecontrolbar_connectpatient]")}`}
						highlight={curentPath.endsWith("/patients") || curentPath.endsWith("/devices")}
						refreshIcon={
							<Button color="primary" size="small" variant="text"
								onClick={() => {
									props.onRouteChange("./patients");
									props.onDisconnect();
								}}
								disabled={!state.patient}
								endIcon={<WifiTetheringOffIcon fontSize="small"/>}>
								<Typography variant="caption">
									{translate("[remotecontrolbar_disconect]")}
								</Typography>
								
							</Button>}>

						{!!state.patient &&
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Avatar name={state.patient.initials ?? state.patient.id.slice(0, 2)} id={state.patient.id} />
								<Button size="small"
									endIcon={<SettingsIcon fontSize="small" />}
									onClick={props.onPatientConfig}>
									{translate("[remotecontrolbar_settings]")}
								</Button>
							</Box>
						}
						{!!state.remoteClient &&
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography flexWrap="wrap">
									<strong>{state.remoteClient.deviceId}  </strong>
									v{state.remoteClient.version} {state.remoteClient.deviceType}
								</Typography>
							</Box>
						}
					</StyledPaper>
				</Grid>

				<Grid item xs={9} sm={4} md={4} xl={6} style={{ height: '120px' }}>
					<StyledPaper title={`2. ${translate("[remotecontrol_processes]")}`}
						highlight={curentPath.endsWith("/processes")}
						refreshIcon={
							<Button color="primary" size="small" variant="text"
								onClick={() => props.onRouteChange("./processes")}
								disabled={!state.patient || !state.remoteClient}
								endIcon={<ChangeCircleIcon fontSize="small"/>}>
								<Typography variant="caption">
									{translate("[remotecontrolbar_change]")}
								</Typography>
							</Button>}>
						<Box
							style={{ width: "100%", justifyContent: 'left', display: 'flex', alignItems: 'left', overflowX: 'auto' }}>
							{!!state.processes &&
								state.processes.map((process, index) => (
									<Box key={index} marginLeft={1}>
										<TooltipCustom content={
											<ModifiersText modifiers={process.modifiers} processID={process.process.id} />
										}>
											<ProcessPill processID={process.process.id} tiny />
										</TooltipCustom>
									</Box>
								))}
						</Box>
					</StyledPaper>
				</Grid>

				<Grid item xs={3} sm={2} md={1} xl={1} style={{ height: '120px' }}>
					<StyledPaper title={`3. ${translate("[remotecontrolbar_control]")}`}
						highlight={curentPath.endsWith("/quickactions")}>
						<Box style={{
							width: "100%",
							textAlign: "center", flexDirection: "column"
						}}>
							<IconButton color="primary"
								onClick={() => props.onRouteChange("./quickactions")}
								disabled={!state.remoteClient}>
								<SettingsRemoteIcon color="inherit" fontSize="large" />
							</IconButton>
						</Box>
					</StyledPaper>
				</Grid>
			</Grid>
		</Box>
	)
}

export default RemoteControlBar;