export const faqTutorials = [
	{
		"title": "Tutorial #1 -Primeros pasos en Re-cognition",
		"content": "En este tutorial, se muestran los primeros pasos necesarios para un correcto manejo de la herramienta Re-cognition. Aprenderá a utilizar la web de control de la herramienta y conocerá a fondo todos los modificadores que hacen que la experiencia del paciente sea personalizable y única.",
		"video": "https://www.youtube.com/embed/804pkyvvDl8"
	},

	{
		"title": "Tutorial #2 -Resumen ejercicios",
		"content": "En este tutorial, se explica de forma resumida, todos los ejercicios de la plataforma Re-cognition y sus características. Podrá conocer de qué forma trabajamos ciertos procesos cognitivos de una forma clara y breve.",
		"video": "https://www.youtube.com/embed/vVgopYFstG0"
	}
];

export const faQuestions = [
	{
		"title": "No logro establecer la conexión de Control Remoto con las gafas de VR.",
		"content": "• Asegúrese de haber iniciado sesión con el mismo usuario de Re-cognition tanto en la plataforma web como en la aplicación de Realidad Virtual. En realidad virtual utilice el menú de la tableta virtual para acceder." +
			"• Asegúrese de que las gafas de realidad virtual están conectadas a internet." +
			"• Vuelva a presionar el botón de Detectar Dispositivos en la sección de control remoto, mientras las gafas están activas." +
			"• Aunque no es estrictamente necesario, intente conectar tanto las gafas de realidad virtual como el navegador web a la misma red local, con acceso a internet."

	},
];

export const notFound = "¿No encuentras la respuesta a tu pregunta? ¡Escríbenos directamente!";