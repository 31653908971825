import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from "react";
import { translate } from '../../../text/translator';
import { ModifiersToolTip } from './ModifierControl';

interface ToggleControlProps {
	title: string,
	description?: string,
	value?: boolean,
	onValueChanged: (value: boolean) => void
}

function ToggleControl(props: ToggleControlProps) {

	const title = props.title;
	const value = !!props.value;
	const onValueChanged = props.onValueChanged;
	const [prevValue, setPrevValue] = useState<boolean | null>(null);

	useEffect(() => {
		if (prevValue !== value) {
			onValueChanged(value);
			setPrevValue(value);
		}
	}, [value, prevValue, onValueChanged]);

	const handleOnChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		onValueChanged(event.target.checked);
	};

	return (
		<Box display="flex" justifyContent="left" alignItems="center">
			<Checkbox onChange={handleOnChanged} checked={value} />
			<Typography>
				{translate(title)}
			</Typography>
			{!!props.description &&
				<ModifiersToolTip description={props.description} />}
		</Box>
	)
}


export default ToggleControl;