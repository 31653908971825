import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import { translate } from '../../../text/translator';
import { ModifiersToolTip } from './ModifierControl';
import { useEffect, useState } from "react";
import ToggleControl from './ToggleControl';

interface MultiSelectControlProps {
	title: string,
	description?: string,
	items: string[],
	value?: boolean[],
	onValueChanged: (value: boolean[]) => void
}

function MultiSelectControl(props: MultiSelectControlProps) {

	const title = props.title;
	const items = props.items;

	const values = props.value ?? new Array(items.length).fill(true);

	const onValueChanged = props.onValueChanged;
	const [prevValue, setPrevValue] = useState<boolean[] | null>(null);

	function arraysEqual(a: boolean[], b: boolean[]): boolean {
		if (a.length !== b.length) return false;

		for (let i = 0; i < a.length; i++) {
			if (a[i] !== b[i]) return false;
		}

		return true;
	}

	useEffect(() => {
		if (!prevValue || !arraysEqual(prevValue, values)) {
			onValueChanged(values);
			setPrevValue(values);
		}
	}, [values, prevValue, onValueChanged]);

	const handleOnChanged = (
		value: boolean,
		index: number,
	) => {
		const newValues: boolean[] = [...values];
		newValues[index] = value;
		onValueChanged(newValues);
	};

	return (
		<Box>
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="multiselect-content"
					id="multiselect-header"
				>
					<Typography>
						{translate(title)}
					</Typography>
					{!!props.description &&
						<ModifiersToolTip description={props.description} />}
				</AccordionSummary>
				<AccordionDetails>
					{items.map((text: string, index: number) => {
						return (
							<ToggleControl title={text}
								value={values[index]}
								key={index}
								onValueChanged={(v) => handleOnChanged(v, index)} />
						)
					})}
				</AccordionDetails>
			</Accordion>
		</Box>
	)
}


export default MultiSelectControl;