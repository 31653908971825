import { Box, useMediaQuery, useTheme } from "@mui/material";
import { addDoc, collection, getFirestore, onSnapshot } from "firebase/firestore";
import { useContext } from "react";
import { UserContext } from "../../contexts/User.context";
import WaveSection from "../landing/WaveSection";
import { Role } from "../login/PremiumUser";
import LicenseProduct from "./LicenseProduct";
import { pricingData } from "./pricingData";
import { useNavigate } from 'react-router-dom';
import { getStripePortal } from "../../adapters/Firebase";

function PricingTable() {

    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const user = useContext(UserContext);

	async function openLicenseManager() {
		const { data } = await getStripePortal()({
			returnUrl: window.location.origin,
			locale: "auto",
		});
		window.location.assign(data.url);
	}
    
    async function buyLicense(data: object) {

        const userID = user?.uid;

        if (!userID) {
            navigate("/login");
            return;
        }

        if(user?.role !== Role.None) {
            openLicenseManager();
            return;
        }

        const firestore = getFirestore();
        const collectionPath = `customers/${userID}/checkout_sessions/`;

        const checkoutData = {
            ...data,
            automatic_tax: true,
            trial_from_plan: true,
            allow_promotion_codes: true,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        }

        const docRef = await addDoc(collection(firestore, collectionPath), checkoutData);

        onSnapshot(docRef, (snap) => {
            const data: any = snap.data();
            if (data.error) {
                alert(`An error occured: ${data.error.message}`);
            }
            if (data.url) {
                window.location.assign(data.url);
            }
        });
    }

    return (

        <WaveSection marginTop={0} marginBottom={0} >
            <Box gap={2}
                display="flex" justifyContent={isSmallScreen ? "space-between" : "center"}
                flexDirection={isSmallScreen ? "column" : "row"}>

                <LicenseProduct title={pricingData.patient.title}
                    description={pricingData.patient.description}
                    role={Role.Patient}
                    price={pricingData.patient.price}
                    features={pricingData.patient.features}
                    onBuy={() => buyLicense({
                        price: "price_1NjfSuHRW4JWV2mKHHbACm65" //live
                        //price: "price_1NZf3OHRW4JWV2mK0t3bI8m6" //test
                    })}
                />

                <LicenseProduct title={pricingData.therapist.title}
                    description={pricingData.therapist.description}
                    price={pricingData.therapist.price}
                    role={Role.Therapist}
                    features={pricingData.therapist.features}
                    onBuy={() => buyLicense({
                        price: "price_1NjfT1HRW4JWV2mKITykmNhX", //live
                        //price: "price_1NZemRHRW4JWV2mKq5Gar9f5", //test
                        tax_id_collection: true
                    })}
                />

                <LicenseProduct title={pricingData.centre.title}
                    description={pricingData.centre.description}
                    price={pricingData.centre.price}
                    role={Role.Centre}
                    features={pricingData.centre.features}
                    onBuy={() => buyLicense({
                        price: "price_1O6rVUHRW4JWV2mK8OBvzkZR", //live
                        //price: "price_1NZemRHRW4JWV2mKq5Gar9f5", //test
                        tax_id_collection: true
                    })
                    }
                />
            </Box>
        </WaveSection>
    );
}

export default PricingTable;
