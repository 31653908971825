import { List, Paper, Typography } from "@mui/material";
import { translate } from "../../text/translator";
import { PatientInvite } from "../center/invite";
import ReceivedPatientInvite from "./ReceivedPatientInvite";
import { useState } from "react";
import HandshakeIcon from '@mui/icons-material/Handshake';
import AlertDialog from "../../components/atoms/Popup";
import { acceptPatientInvitation, rejectPatientInvitation } from "../../adapters/Firebase";

interface ReceivedInvitesProps {
    invitations: PatientInvite[]
}

function ReceivedPatientInvites(props: ReceivedInvitesProps) {
    const [invitationError, setInvitationError] = useState<string | null>(null);

    async function handleAcceptInvitation(invitation: PatientInvite) {
        const result = await acceptPatientInvitation(invitation.therapistID);
        if (result.code !== 200) {
            setInvitationError(() => result.message);
        }
    }

    async function handleRejectInvitation(invitation: PatientInvite) {
        const result = await rejectPatientInvitation(invitation.therapistID);
        if (result.code !== 200) {
            setInvitationError(() => result.message);
        }
    }

    return (
        <>
            <Typography variant="h5" gutterBottom noWrap>
                {translate("[patient_invitations]")}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {translate("[patient_invitations_description]")}
            </Typography>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <List>
                    {
                        Array.from(props.invitations).map((invitation: PatientInvite, index: number) => (
                            <ReceivedPatientInvite
                                invitation={invitation}
                                key={index}
                                icon={<HandshakeIcon color="secondary" />}
                                onAccept={() => handleAcceptInvitation(invitation)}
                                onCancel={() => handleRejectInvitation(invitation)} />
                        ))
                    }
                </List>
            </Paper>


            < AlertDialog
                open={!!invitationError}
                title={translate("[center_invitationerror_title]")}
                cancelTitle={translate("[center_accept]")}
                onCancel={() => setInvitationError(() => null)} >
                {translate(invitationError!)}
            </AlertDialog>

        </>
    );
}

export default ReceivedPatientInvites;