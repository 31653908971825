import { ReactNode, useContext, useMemo, useState } from "react";
import { CachedDocument, getProcesses } from "../adapters/Firebase";
import { Process } from "../data/Process";
import { ProcessesContext } from "./Processes.context";
import { UserContext } from "./User.context";

interface ProcessesProviderProps {
	children: ReactNode;
}

function docToProcess(doc: any, id: string): Process {

	const modifiers = Object.keys(doc.modifiers).map(key => {
		return {
			id: key,
			title: doc.modifiers[key].title,
			description: doc.modifiers[key].description,
			format: doc.modifiers[key].format,
			payload: doc.modifiers[key].payload,
			default: doc.modifiers[key].default,
			order: doc.modifiers[key].order,
			isFixed: doc.modifiers[key].fixed
		};
	});

	return {
		id: id,
		name: doc.name,
		tag: doc.tag,
		type: doc.type,
		isPublic: !!doc.isPublic,
		modifiers: modifiers,
		minVersion: doc.minVersion
	}
}

function ProcessesProvider(props: ProcessesProviderProps) {

	const user = useContext(UserContext);
	const [processes, setProcesses] = useState<Process[]>([]);

	useMemo(() => {
		if (!user) {
			setProcesses([]);
			return;
		}
		getProcesses().then((retrievedProcesses: any) => {
			const processArr: Process[] = retrievedProcesses.map((p: CachedDocument) => docToProcess(p.doc, p.id));
			setProcesses(()=>processArr);
		});
	}, [user]);

	return (
		<ProcessesContext.Provider value={{ processes, setProcesses }}>
			{props.children}
		</ProcessesContext.Provider>);
}

export default ProcessesProvider;