import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getStripePortal } from '../../adapters/Firebase';
import { translate } from "../../text/translator";


interface LicenseButtonProps {
	hasLicense: boolean,
}


function LicenseButton(props: LicenseButtonProps) {

	const navigate = useNavigate();
	const hasLicense = props.hasLicense;

	async function openLicenseManager() {
		const { data } = await getStripePortal()({
			returnUrl: window.location.origin,
			locale: "auto",
		});
		window.location.assign(data.url);
	}

	const handleSuscription = () => {
		openLicenseManager();
	}

	const handleBuy = () => {
		navigate("/buy");
	}

	return (
		<>
			{!hasLicense ?
				<Button onClick={handleBuy} variant="contained"
					startIcon={<ShoppingCartIcon />}>
					{translate("[usermenu_buy]")}
				</Button> :
				<Button style={{ fontWeight: "bold" }} onClick={handleSuscription}>
					{translate("[usermenu_subscription]")} →
				</Button>
			}
		</>
	);
}

export default LicenseButton;