import { Box, Typography } from "@mui/material";
import { translate } from "../../text/translator";
import { MultiObjectData } from "../MultiObjectData";

interface AverageCardData {
	data: MultiObjectData
}


function AverageCard(props: AverageCardData) {
	return (<>
		<Typography><strong>{translate("[graphs_finalresult]")}</strong></Typography>
		<br />
		<Box><Typography>{translate("[graph_multiobject_total_time]")}</Typography></Box>
		<Box><Typography variant="h4">{props.data.reactionTime.toFixed(2)} sec</Typography></Box>

		<Box><Typography>{translate("[graph_multiobject_avg_reaction_time]")}</Typography></Box>
		<Box><Typography variant="h4">{(props.data.reactionTime / props.data.count).toFixed(2)} sec</Typography></Box>

		<Box><Typography>{translate("[graph_multiobject_error_rate]")}</Typography></Box>
		<Box><Typography variant="h4">{((props.data.errors / props.data.triesCount) * 100).toFixed(1)}%</Typography></Box>
		<br />
		<Box style={{justifyContent: "space-between", display: "flex"}}>
			<Typography><strong>{translate("[graph_multiobject_errors]")}</strong> {props.data.errors}</Typography>
			<Typography><strong>{translate("[graph_multiobject_clues]")}</strong> {props.data.usedClues}</Typography>
		</Box>
	</>);
}

export default AverageCard;