import { ReactComponent as IconAttention } from '../../media/attention.svg';
import { ReactComponent as IconMotor } from '../../media/bicep.svg';
import { ReactComponent as IconGonogo } from '../../media/gonogo.svg';
import { ReactComponent as IconMemory } from '../../media/memory.svg';

export interface WhatDoesItWorkData {
	title: string;
	description: string;
	icon: any;
}

export const whatDoesItWorkTitle = "¿Qué trabaja?";

export const whatDoesItWorkData = {
	items: [
		{
			title: "Memoria",
			description: "Trabaja principalmente la capacidad de retener información y recuperarla voluntariamente tras un periodo limitado de tiempo. Además de forma simultánea se trabajan procesos atencionales e impulsividad.",
			icon: IconMemory,
		},
		{
			title: "Inhibición",
			description: "Este bloque está formado por ejercicios para aumentar la capacidad de concentración y la resistencia ante distractores, reteniendo la información relevante e inhibiendo o controlando respuestas indeseadas.",
			icon: IconGonogo,
		},
		{
			title: "Atención",
			description: "Trabaja la capacidad de concentración y velocidad de reacción de los usuarios. Manteniendo, seleccionando y dirigiendo la atención hacia la información relevante del contexto.",
			icon: IconAttention,
		},
		{
			title: "Motrices y Funcionales",
			description: "Esta aplicación además trabaja la psicomotricidad, control postural, heminegligencia y lateralidad, habilidades visoespaciales, integración propioceptiva y otras funciones ejecutivas.",
			icon: IconMotor,
		},
	],

	chartData: [
		{
			title: "Psicomotor",
			value: 0.2,
		},
		{
			title: "Atención",
			value: 0.2,
		},
		{
			title: "Memoria",
			value: 0.2,
		},
		{
			title: "Velocidad proc.",
			value: 0.2,
		},
		{
			title: "Inhibición",
			value: 0.2,
		},
	],

	paragraph1: "“Re-Cognition es un entorno de realidad virtual cuyo objetivo se basa en la mejora de los componentes cognitivos y de la ejecución en diversas tareas cotidianas.”",
	paragraph2: "“Está destinado a personas con daño cerebral adquirido, niños con necesidades educativas especiales y cualquier usuario que precise una mejora cognitiva.”",
	video: "https://www.youtube.com/embed/jriogLzArgM"
}