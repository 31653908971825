import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect } from "react";
import Logo from "../../media/logo";
import LogoText from "../../media/logoText";
import { translate } from '../../text/translator';
import SigninPanel from "./SigninPanel";

function SignInScreen() {
	const theme = useTheme();

	useEffect(() => {
		document.body.style.backgroundColor = theme.palette.primary.main;
		return () => {
			document.body.style.backgroundColor = theme.palette.background.default;
		};
	}, [theme.palette.background.default, theme.palette.primary.main])

	return (
		<>
			<Box style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', gap: '30px', }}>
				<Box style={{ borderRadius: "50%", backgroundColor: `${theme.palette.background.paper}` }}>
					<Logo height="40" width="40" style={{ margin: 5 }} />
				</Box>
				<LogoText height="60" width="260" fill={theme.palette.background.paper} style={{ alignself: "center" }} />
			</Box>
			<br />
			<br />
			<Typography variant="h2" style={{ textAlign: "center" }} color={theme.palette.background.paper}>
				{translate("[login_title]")}
			</Typography>
			<br />

			<SigninPanel />

		</>
	);
}

export default SignInScreen