import backend from './BackendNaming.json';
import birds from './BirdsTranslation.json';
import food from './FoodTranslation.json';
import fruits from './FruitsTranslation.json';
import gonogo from './GonogoTranslation.json';
import dishwasher from './DishwasherTranslation.json';
import cleanSurface from './CleanSurfaceTranslation.json';
import items from './ItemsTranslation.json';
import dashboard from './DashboardTranslation.json'

type TranslationObject = {
	[Language: string]: {
		[key: string]: string;
	};
};

const es = "es";
const en = "en";

const language = es;

const translations: TranslationObject = {
	es: {
		...backend[es],
		...birds[es],
		...food[es],
		...fruits[es],
		...dashboard[es],
		...gonogo[es],
		...dishwasher[es],
		...cleanSurface[es]

	},
	en: {
		...backend[en],
		...birds[en],
		...food[en],
		...fruits[en],
		...dashboard[en],
		...gonogo[en],
		...dishwasher[en],
		...cleanSurface[en]
	}
};

const itemsTranslation: any = {
	es: {
		...items[es]
	},
	en: {
		...items[en]
	}
}

const tagRegex = /\[[^\]]*\]/g
const itemRegex = /{(?<objectKey>[\w_]+)\s*(?:color=\[(?<color>[\w_]+)\])?\s*(?:size=\[(?<size>[\w_]+)\])?\s*(?:laterality=\[(?<laterality>[\w_]+)\])?\s*(?:handedness=\[(?<handedness>[\w_]+)\])?}/gi;
const blanksRegex = /\s+/g;


function translateItems(text: string): string {

	const getItemText = ((groups: { [key: string]: string }): string => {

		const items = itemsTranslation[language];
		const objectTitleFormat = items.object_title_format;
		const objectKey = groups["objectKey"];
		const objectNode = items[objectKey];
		if (!objectNode) {
			return "<Missing/>";
		}

		const concordanceKey = objectNode["concordance"];
		const colorKey = groups["color"];
		const sizeKey = groups["size"];
		const lateralityKey = groups["laterality"];
		const handednessKey = groups["handedness"];

		const objectName = objectNode.name;
		const color = items[colorKey] ? items[colorKey][concordanceKey] : "";
		const size = items[sizeKey] ? items[sizeKey][concordanceKey] : "";
		const article = items["article"][concordanceKey] ?? "";
		const laterality = items[lateralityKey] ?? "";
		const handedness = items[handednessKey] ?? "";

		let objectTitle = objectTitleFormat
			.replace('{0}', article)
			.replace('{1}', objectName)
			.replace('{2}', color)
			.replace('{3}', size)
			.replace('{4}', laterality)
			.replace('{5}', handedness);

		objectTitle = objectTitle.replace(blanksRegex, " ");
		objectTitle = objectTitle.trim();
		return objectTitle;
	});

	let result = text;
	let match;
	while (!!(match = itemRegex.exec(text))) {
		const groups = match.groups;
		if (groups) {
			const itemText = getItemText(groups);
			result = result.replace(match[0], itemText);
		}
	}

	return result;
}

function translateText(text: string): string {
	const matches = text.match(tagRegex);
	matches?.forEach(match => {
		const key = match.substring(1, match.length - 1);
		const translation = translations[language][key];
		if (translation) {
			text = text.replace(match, translation);
		}
	});
	return text;
}

function translateTags(text: string): string {
	return text
		.replace(/<align=center>(.*?)<\/align>/g, "$1")
		.replace(/<align=center>(.*?)/g, "$1")
		.replace(/<b>(.*?)<\/b>/g, "**$1**")
		.replace(/<s>(.*?)<\/s>/g, "~~$1~~")
		.replace(/<i>(.*?)<\/i>/g, "*$1*")
		.replace(/\n/g, "  \n")
}

export function translateWithObject(text: string): string {
	text = translateItems(text);
	text = translateText(text);
	text = translateTags(text);
	return text;
}

export function translate(text: string): string {
	if (!text) {
		return "";
	}
	text = translateText(text);
	text = translateTags(text);
	return text;
};