import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { researchData } from "./researchData";

function Research() {

	const theme = useTheme();
	const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

	return (
		<div>
			<Typography variant="h2" gutterBottom>
				{"I+D+i"}
			</Typography>
			<Box
				gap={2}
				display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
				<Box width="100%">
					<Typography variant="h5" gutterBottom>
						{researchData.goal}
					</Typography>
					<img src={researchData.image} alt="" style={{ width: "100%" }} />
				</Box>

				<Box width="100%">
					<Typography variant="body1" >{researchData.paragraph}</Typography>
				</Box>

				<Box
					width="100%"
					justifyContent="space-between"
					color={theme.palette.primary.contrastText} >
					<Carousel autoPlay cycleNavigation animation="slide">
						{researchData.studies.map((item, i) => (
							<Box key={i} style={{
								backgroundImage: `url(${item.image})`,
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
								aspectRatio: "3/4",
							}}>
								<Box style={{ backgroundColor: "#333333A0" }}
									padding={2}
									height="100%"
									display="flex" textAlign="center" flexDirection="column"
									justifyContent="space-between"	>
									<div>
										<Typography variant="h5" gutterBottom>
											{item.title}
										</Typography><Typography variant="body2" gutterBottom>
											{item.description}
										</Typography>
									</div>
									<Button color="inherit" variant="outlined"
										onClick={() => window.open(item.url, "_blank")} >
										Leer
									</Button>
								</Box>
							</Box>
						))}
					</Carousel>
				</Box>
			</Box>
		</div >
	);
}

export default Research;