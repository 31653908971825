import { RemoteControlTag, toRemoteControlMessage } from "../features/remoteControl/remoteControlMessage";


export interface Patient {
	id: string;
	userID?: string;
	index?: number;
	settings: PatientSettingsData
	initials?: string;
}

export interface PatientSettingsData {
	readingSpeed: number;
	spacing: number;
	vibrance: number;
	hands: number;
	leftHand: boolean;
	rightHand: boolean;
	tremors: boolean;
	instructionsMode: number;
	hideButton: boolean;
	heightOffset: number;
	tutorial: boolean;
	autoGrab: boolean;
	lateralityPosition: number;
}

export const defaultPatientSettings: PatientSettingsData = {
	readingSpeed: 1,
	spacing: 1,
	vibrance: 0,
	hands: 0,
	leftHand: true,
	rightHand: true,
	tremors: false,
	instructionsMode: 1,
	hideButton: false,
	heightOffset: 0,
	tutorial: true,
	autoGrab: false,
	lateralityPosition: 1,
};

export function getPatientInitials(patient: any) {
	return !!patient.doc?.initials ? patient.doc.initials :
		`@${patient.id.slice(0, 2).toUpperCase()}`;
}

export function getPatientName(patient: any) {
	return !!patient.doc.email ? patient.doc.email : patient.id;
}

export function getPatientIndex(patient: any) {
	return patient.doc.index ? `${patient.doc.index}` : "";
}

export function serializeSettings(data: PatientSettingsData): string[] {
	const safeData = {...defaultPatientSettings, ...data } as PatientSettingsData;
	return [
		toRemoteControlMessage(RemoteControlTag.enableTTS, safeData.instructionsMode.toString()),
		toRemoteControlMessage(RemoteControlTag.readingSpeed, safeData.readingSpeed.toString()),
		toRemoteControlMessage(RemoteControlTag.itemsSpacing, safeData.spacing.toString()),
		toRemoteControlMessage(RemoteControlTag.vibranceRequested, safeData.vibrance.toString()),
		toRemoteControlMessage(RemoteControlTag.handMaterialRequested, safeData.hands.toString()),
		toRemoteControlMessage(RemoteControlTag.enableLeftHand, safeData.leftHand.toString()),
		toRemoteControlMessage(RemoteControlTag.enableRightHand, safeData.rightHand.toString()),
		toRemoteControlMessage(RemoteControlTag.smoothTremors, safeData.tremors.toString()),
		toRemoteControlMessage(RemoteControlTag.hideButton, safeData.hideButton.toString()),
		toRemoteControlMessage(RemoteControlTag.tutorial, safeData.tutorial.toString()),
		toRemoteControlMessage(RemoteControlTag.heightOffset, safeData.heightOffset.toString()),
		toRemoteControlMessage(RemoteControlTag.autoGrab, safeData.autoGrab.toString()),
		toRemoteControlMessage(RemoteControlTag.lateralityPosition, safeData.lateralityPosition.toString()),
	];
}