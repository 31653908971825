import lucaImage from "../../media/landing/LUCA.png";
import anaImage from "../../media/landing/ANA.png";
import joseImage from "../../media/landing/JOSE.png";

export interface TeamMemberData {
	image: string,
	name: string,
	title: string,
	description: string,
	twitterUrl: string,
	linkedInUrl: string,
}

export const teamTitle = "Equipo";

export const teamData: TeamMemberData[] = [
	{
		"name": "José María Torralba",
		"image": joseImage,
		"title": "CEO",
		"description": "Neuropsicólogo Clínico con +10 años de experiencia en DCA. Docente e Investigador en diferentes Universidades. Fundador de Createc3D.",
		"twitterUrl": "https://twitter.com/JM_Torralba_",
		"linkedInUrl": "https://www.linkedin.com/in/jose-maria-torralba-mu%C3%B1oz-938b5444/",
	},
	{
		"name": "Ana Ibañez",
		"image": anaImage,
		"title": "CSO",
		"description": "Neuropsicóloga Clínica con +5 años de experiencia en problemas del Neurodesarrollo. Investigación en Hospitales y centros de DCA. Experta en evaluación y tratamiento.",
		"twitterUrl": "https://twitter.com/Anaibga",
		"linkedInUrl": "https://www.linkedin.com/in/ana-ib%C3%A1%C3%B1ez-b3ba0926b/",
	},
	{
		"name": "Luca Mefisto",
		"image": lucaImage,
		"title": "Ingeniero consultor",
		"description": "Ingeniero en Realidad Virtual con +10 años de experiencia. Ha trabajado para compañias como Mercedes, General Electric o MasterCard. Fundador de VR-Manchester y Granada-Interactiva.",
		"twitterUrl": "https://twitter.com/LucaMefisto",
		"linkedInUrl": "https://www.linkedin.com/in/lucamefisto/",
	},
];