import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useTheme } from '@mui/material/styles';
import { HandednessData } from "../DataDefinitions";
import { renderBlackLegend } from "./legend";
import { Typography } from "@mui/material";
import { translate } from "../../text/translator";

interface HandednessGraphData {
	data: HandednessData
}

const RADIAN = Math.PI / 180;
const labelLateralidad = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);
	return (
		<text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" >
			{percent > 0 && `${(percent * 100).toFixed(1)}%`}
		</text>
	);
};

const labelErrors= ({ cx, cy, midAngle, innerRadius, outerRadius, value }: any) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN) * 0.8;
	const y = cy;
	return (
		<text x={x} y={y} textAnchor="middle" dominantBaseline="top" >
			{value}
		</text>
	);
};


function HandednessGraph(props: HandednessGraphData) {
	const colors = useTheme().palette;

	return (
		<>
			<Typography><strong>{translate("[graphs_handedness_title]")}</strong></Typography>
			<ResponsiveContainer height={300}>
				<PieChart>
					<Legend formatter={renderBlackLegend} />
					<Pie
						width="100%"
						dataKey="value"
						startAngle={180}
						endAngle={0}
						data={
							[{ hand: "left", value: props.data.left },
							{ hand: "right", value: props.data.right }]}
						outerRadius={120}
						innerRadius={60}
						labelLine={false}
						cy="80%"
						label={labelLateralidad}>
						<Cell name={translate("[graphs_handedness_left]")} key="left-hand" fill={colors.primary.dark} />
						<Cell name={translate("[graphs_handedness_right]")} key="right-hand" fill={colors.primary.light} />
					</Pie>

					<Pie
						width="100%"
						dataKey="value"
						startAngle={0}
						endAngle={props.data.rightErrors / (props.data.left + props.data.right) * 180}
						legendType="none"
						fill={colors.error.light}
						data={
							[{ hand: "errors", value: props.data.rightErrors }]}
						outerRadius={60}
						innerRadius={50}
						labelLine={false}
						cy="80%"
						label={labelErrors}>
					</Pie>

					<Pie
						width="100%"
						dataKey="value"
						startAngle={180 - props.data.leftErrors / (props.data.left + props.data.right) * 180}
						endAngle={180}
						legendType="circle"
						fill={colors.error.light}
						data={
							[{ hand: "errors", value: props.data.leftErrors }]}
						outerRadius={60}
						innerRadius={50}
						labelLine={false}
						cy="80%"
						label={labelErrors}>						
						<Cell name={translate("[graphs_distribution_errors]")} key="errors-hand" />

					</Pie>

				</PieChart>
			</ResponsiveContainer>
		</>);

}

export default HandednessGraph;