
import { Box, Grid, Typography } from '@mui/material';
import TeamMember from './TeamMember';
import { teamData, teamTitle } from "./teamData"

function OurTeam() {

	return (
		<Box my={10} >
			<Typography variant="h2" gutterBottom>
				{teamTitle}
			</Typography>

			<Grid container spacing={2}>
				{teamData.map((entry, index) => {
					return (
						<Grid item xs={12} sm={4} lg={4} key={index}>
							<TeamMember
								teamMember={entry} />
						</Grid>)
				})}
			</Grid>
		</Box>
	);
}

export default OurTeam;