import { ModifierDefinition } from "../data/ModifierDefinitions";
import { translate } from "../text/translator";


export interface Process {
	name: string;
	tag: string;
	type: string;
	id: string;
	isPublic: boolean;
	minVersion: number;
	modifiers: ModifierDefinition<any>[];
}

export function getProcessTitle(processID: string) : string {
	return translate(`[process_${processID}_title]`);
}

export function getProcessDescription(processID: string): string {
	return translate(`[process_${processID}_desc]`);
}
