import { Typography, Box } from "@mui/material";
import { translate } from "../../text/translator";
import { FruitDeliveryStatus, FruitsData } from "../FruitsData";

interface AverageFruitsData {
	data: FruitsData
}

function AverageFruitsCard(props: AverageFruitsData) {
	const data: FruitsData = props.data;

	const forbidden = data.tries.filter(t => t.isForbidden && t.deliveryStatus !== FruitDeliveryStatus.NotDelivered).length;
	const nonBinned = data.tries.filter(t => !t.isForbidden && t.deliveryStatus !== FruitDeliveryStatus.Binned).length;
	const correct = data.tries.filter(t => !t.isForbidden && t.deliveryStatus === FruitDeliveryStatus.Binned).length;

	const picked = data.tries.filter(t => t.deliveryStatus !== FruitDeliveryStatus.NotDelivered).length;
	const averageReactionTime = data.totalTime / picked;

	return (<>

		<Typography><strong>{translate("[graphs_finalresult]")}</strong></Typography>
		<br />

		<Box>
			<Typography>{translate("[graphs_fruits_total_time]")}</Typography>
			<Typography variant="h4">
				{(data.totalTime).toFixed(1)} {translate("[units_seconds]")}
			</Typography>
		</Box>

		<Box>
			<Typography>{translate("[graphs_fruits_avg_reaction_time]")}</Typography>
			<Typography variant="h4">
				{averageReactionTime.toFixed(2)} {translate("[units_seconds]")}
			</Typography>
			</Box>

		<Box>
			<Typography>{translate("[graphs_fruits_correct_oranges]")}</Typography>
			<Typography variant="h4">
			{correct}
		</Typography>
			</Box>

		<br />

		<Box style={{ justifyContent: "space-between", display: "flex" }}>
			<Typography><strong>{translate("[graphs_fruits_green_oranges_picked]")}:</strong> {forbidden}</Typography>
			<Typography><strong>{translate("[graphs_fruits_ripe_oranges_wasted]")}:</strong> {nonBinned}</Typography>
		</Box>
	</>);
}

export default AverageFruitsCard;