import { Button, SvgIcon } from '@mui/material';
import { translate } from '../../../text/translator';

export interface QuickActionData {
	name: string;
	icon: any;
	message: string;
	variant: "contained" | "outlined" | "text";
}

interface QuickActionButtonProps {
	data: QuickActionData;
	onClick: () => void;
}

function QuickActionButton(props: QuickActionButtonProps) {
	const icon = props.data.icon!;
	const title = translate(props.data.name);

	return (
		<Button variant={props.data.variant} fullWidth onClick={props.onClick}
			startIcon={<SvgIcon component={icon}
				viewBox="0 0 64 64" />}>
			{title}
		</Button>
	);
};

export default QuickActionButton; 