import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import { userReviewsData, UserReviewData, userReviewsTitle } from './userReviewsData';

interface UserReviewCardProps {
	userReview: UserReviewData;
}

const height = "200px";

function UserReviewCard(props: UserReviewCardProps) {

	const theme = useTheme();

	return (
		<Box display="flex" textAlign="center" flexDirection="column" alignItems="center"
			height={height} maxWidth={300} >
			<Box style={{ backgroundColor: theme.palette.primary.light }}
				color={theme.palette.primary.contrastText}
				padding={1} >

				<Typography variant="body1" gutterBottom>
					{`“${props.userReview.review}”`}
				</Typography>
			</Box>

			<svg width="20px" height="10px" viewBox="0 0 1 1" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M 0,0 L 1,0 0.5,1 0,0" fill={theme.palette.primary.light} />
			</svg>

			<Typography variant="h6">
				{props.userReview.name}
			</Typography>
			<Typography variant="subtitle2">
				{props.userReview.title}
			</Typography>
		</Box>)
}

function UserReviews() {
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const perChunk = isSmallScreen ? 1 : 3;
	const result = userReviewsData.reduce((resultArray: UserReviewData[][], item, index) => {
		const chunkIndex = Math.floor(index / perChunk)

		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = [];
		}

		resultArray[chunkIndex].push(item);

		return resultArray;
	}, []);

	return (
		<Box my={4}>
			<Typography variant="h2" gutterBottom>
				{userReviewsTitle}
			</Typography>
			<Carousel autoPlay cycleNavigation animation="slide" height={height}>
				{result.map((reviews: UserReviewData[], index: number) => {
					return (
						<Box key={index} display="flex" justifyContent="space-around" gap={2} >
							{
								reviews.map((userReview: UserReviewData, index: number) =>
									<UserReviewCard key={index} userReview={userReview} />)}
						</Box>)
				})
				}
			</Carousel>
		</Box>);
}

export default UserReviews;
