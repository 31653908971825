import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, Card, CardContent, CardMedia, IconButton, Typography } from "@mui/material";
import { TeamMemberData } from "./teamData";

interface TeamMemberProps {
    teamMember: TeamMemberData,
}

function TeamMember(props: TeamMemberProps) {

    const teamMember: TeamMemberData = props.teamMember;
    return (
        <Card>
            <CardMedia
                component="img"
                height="140"
                image={teamMember.image}
                title={teamMember.name}
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="h3">
                    {teamMember.name}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    {teamMember.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {teamMember.description}
                </Typography>
                <Box mt={2} mb={2} gap={2} display="flex" justifyContent="center">
               
                    <a href={teamMember.twitterUrl} target="_blank" rel="noopener noreferrer">
                        <IconButton color="secondary">
                            <TwitterIcon />
                        </IconButton>
                    </a>
                    <a href={teamMember.linkedInUrl} target="_blank" rel="noopener noreferrer">
                        <IconButton color="secondary">
                            <LinkedInIcon />
                        </IconButton>
                    </a>
                </Box>
            </CardContent>
        </Card>);
}

export default TeamMember;