import { Box, Typography, TextField, Button } from '@mui/material';
import { sendEmail } from '../../adapters/Firebase';
import { useContext, useRef, useState } from 'react';
import { UserContext } from '../../contexts/User.context';
import { translate } from '../../text/translator';

function Newsletter() {
	const [sending, setSending] = useState<boolean>(false);
	const formRef = useRef<HTMLFormElement>(null);
	const user = useContext(UserContext);

	const onSubmit = async (event: any) => {
		setSending(() => true);
		event.preventDefault();
		const { email } = event.target.elements;

		const result = await sendEmail("[Newsletter]", email.value, "Deseo entrar en la lista de correo.");

		setSending(() => false);

		if (result && formRef.current) {
			formRef.current.reset();
		}
	};

	return (
		<Box >
			<Box
				display="flex"
				flexDirection="column"
				alignItems="flex-start"
				maxWidth={600}
				margin="0 auto"
				component="form"
				onSubmit={onSubmit}
				ref={formRef}
				sx={{
					'& .MuiTextField-root': { m: 1, width: '100%' },
					'& .MuiButtonBase-root': { m: 1 }
				}}
			>
				<Typography variant="subtitle1" gutterBottom>
					{translate("[newsletter_description]")}
				</Typography>

				<TextField
					name="email"
					label={translate("[contact_email]")}
					variant="outlined"
					type="email"
					required
					autoComplete="email"
					defaultValue={user?.email}
				/>
				<Button type="submit" variant="contained" color="primary" disabled={sending}>
					{translate("[newsletter_subscribe]")}
				</Button>
				<Typography variant="caption" gutterBottom>
					{translate("[newsletter_warning]")}
				</Typography>
			</Box>
		</Box>
	);
}

export default Newsletter;
