import { Button } from "@mui/material";
import Paper from '@mui/material/Paper';
import { useMemo, useState } from "react";
import { ReactNode } from "react-markdown/lib/ast-to-react";
import { useParams } from "react-router-dom";
import { CachedDocument, getSession } from "../../adapters/Firebase/Firebase";
import BirdsSession from "../../components/BirdsSession";
import DishwasherSession from "../../components/DishwasherSession";
import FruitsSession from "../../components/FruitsSession";
import GonogoSession from "../../components/GonogoSession";
import GrillSession from "../../components/GrillSession";
import MultiobjectSession from "../../components/MultiobjectSession";
import SessionHeader from "../../features/session/SessionHeader";
import { translate } from "../../text/translator";
import { SessionTagSmall } from "./SessionTag";
import CleanSurfaceSession from "../../components/Graphs/CleanSurface/CleanSurfaceSession";

interface SessionsVisualizerProps {

}

function SessionVisualizer(props: SessionsVisualizerProps) {
	const { sessionIds: sessionId } = useParams();
	const [selectedSessions, setSelectedSessions] = useState([] as CachedDocument[]);
	const [isComparing, setIsComparing] = useState(false);

	useMemo(() => {
		if (sessionId === undefined) {
			setSelectedSessions([]);
			return;
		}

		const selectedSessionsIds: string[] = sessionId.split("&").filter(String);
		selectedSessionsIds.filter((s) => s !== undefined);
		for (let i = 0; i < selectedSessionsIds.length; i++) {
			const sessionID = selectedSessionsIds[i];
			getSession(sessionID)
				.then((retrievedSession: any) => {
					setSelectedSessions((oldArray) => [...oldArray, retrievedSession]);
				});
		}


	}, [sessionId]);

	function indexToSessionCode(index: number): string {
		return String.fromCharCode(index + "A".charCodeAt(0));
	}

	function getDate(date: Date) {
		return new Intl.DateTimeFormat('es-es', { dateStyle: 'medium', timeStyle: 'short' }).format(date);
	}

	function renderGraph(session: any, index: number) {
		switch (session?.doc?.processType) {
			case "multiobject": return (
				<MultiobjectSession
					graphElements={selectedSessions.length > 1 ? selectedSessions.length : undefined}
					key={session.id}
					sessionDoc={session}>
					<SessionTagSmall name={indexToSessionCode(index)} />
				</MultiobjectSession>);
			case "gonogo": return (
				<GonogoSession
					graphElements={selectedSessions.length > 1 ? selectedSessions.length : undefined}
					key={session.id}
					sessionDoc={session}>
					<SessionTagSmall name={indexToSessionCode(index)} />
				</GonogoSession>);
			case "grill": return (
				<GrillSession
					graphElements={selectedSessions.length > 1 ? selectedSessions.length : undefined}
					key={session.id}
					sessionDoc={session}>
					<SessionTagSmall name={indexToSessionCode(index)} />
				</GrillSession>);
			case "birds": return (
				<BirdsSession
					graphElements={selectedSessions.length > 1 ? selectedSessions.length : undefined}
					key={session.id}
					sessionDoc={session}>
					<SessionTagSmall name={indexToSessionCode(index)} />
				</BirdsSession>);
			case "fruits": return (
				<FruitsSession
					graphElements={selectedSessions.length > 1 ? selectedSessions.length : undefined}
					key={session.id}
					sessionDoc={session}>
					<SessionTagSmall name={indexToSessionCode(index)} />
				</FruitsSession>);
			case "dishwasher": return (
				<DishwasherSession
					graphElements={selectedSessions.length > 1 ? selectedSessions.length : undefined}
					key={session.id}
					sessionDoc={session}>
					<SessionTagSmall name={indexToSessionCode(index)} />
				</DishwasherSession>);
			case "cleanSurface": return (
				<CleanSurfaceSession
					graphElements={selectedSessions.length > 1 ? selectedSessions.length : undefined}
					key={session.id}
					sessionDoc={session}>
					<SessionTagSmall name={indexToSessionCode(index)} />
				</CleanSurfaceSession>);
			default: return (<></>);
		}
	}


	function RenderSessionHeader(index: number, doc: any): ReactNode {
		return <Paper key={index} style={{ width: '100%' }} className="session-wrapper">
			<div style={{ padding: "20px", flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
				<SessionHeader
					index={indexToSessionCode(index)}
					processID={doc.processID}
					modifiers={doc.modifiers}
					userID={doc.userID}
					timestamp={getDate(new Date(doc.timestamp?.seconds * 1000))}
					sessionTag={indexToSessionCode(index)} />
			</div>
		</Paper>
	}


	function comparingSession() {
		return <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }} className="session-wrapper">
			{selectedSessions.map((session: CachedDocument, index: number) =>
				<div key={index} className="session-block" style={{ flex: 1, minWidth: '50%', display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
					{RenderSessionHeader(index, session.doc)}
					{renderGraph(session, index)}
				</div>)}
		</div>;
	}

	function relatedSession() {
		return <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }} className="session-wrapper">
			{selectedSessions.map((session: CachedDocument, index: number) =>
				RenderSessionHeader(index, session.doc))}
			{selectedSessions.map((session: CachedDocument, index: number) =>
				renderGraph(session, index))}
		</div>
	}

	if (!selectedSessions) { return <></>; }

	return (
		<>
			<div style={{ textAlign: "right" }} className="session-unprintable">
				<Button onClick={() => setIsComparing(!isComparing)}>
					{isComparing ? translate("[session_compareentangled]") : translate("[session_comparesequential]")}
				</Button>
				<Button onClick={window.print}>
					{translate("[session_print]")}
				</Button>
			</div>
			<br className="session-unprintable"/>
			{isComparing ? comparingSession() : relatedSession()}
		</>
	);

}

export default SessionVisualizer;