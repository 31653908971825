import { Button, Link, Typography } from "@mui/material";
import { translate } from "../../text/translator";

function VRAccess() {
	return (
		<>
			<Typography variant="h5">
				{translate("[vraccess_requirements_title]")}
			</Typography>
			<Typography variant="body1" gutterBottom component="div">
				<p>{translate("[vraccess_requirements_description1]")}<Link href="https://www.meta.com/" target="_blank">Meta</Link>.</p>
				<p>{translate("[vraccess_requirements_description2]")} 
					<Link href="https://www.meta.com/es/en/quest/products/quest-2/" target="_blank">Meta Quest 2</Link> o <Link href="https://www.meta.com/es/en/quest/quest-3/" target="_blank">Meta Quest 3</Link>.</p>
			</Typography>

			<Typography variant="h5">
				{translate("[vraccess_steps_title]")}
			</Typography>

			<Typography variant="body1" gutterBottom component="div">
				<p>{translate("[vraccess_steps_join_description]")}</p>
				<Button variant="contained" color="primary" href="https://www.meta.com/s/3kfhxl3GA" target="_blank">
					{translate("[vraccess_steps_join_button]")}
				</Button>
				<p>{translate("[vraccess_steps_install_description]")}</p>
				<Button variant="contained" color="primary" href="https://www.meta.com/experiences/4144031432312694" target="_blank">
					{translate("[vraccess_steps_install_button]")}
				</Button>
				<p>{translate("[vraccess_steps_login_description]")}</p>
			</Typography>
		</>
	);
}

export default VRAccess;