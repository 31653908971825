import { CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { getAuth, signOut } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Initialize } from './adapters/Firebase';
import './App.css';
import { Content } from './App.styles';
import { DashboardLayout } from './components/atoms/DashboardLayout';
import ProcessesProvider from './contexts/ProcessesProvider';
import { UserContext } from './contexts/User.context';
import PricingPage from './features/buying/PricingPage';
import Faq from './features/faq/Faq';
import LandingPage from './features/landing/LandingPage';
import PrivacyPolicy from './features/legal/PrivacyPolicy';
import { getRole, PremiumUser, Role } from './features/login/PremiumUser';
import SignInScreen from './features/login/SigninScreen';
import RemoteControl from './features/remoteControl/RemoteControl';
import RemoteControlProvider from './features/remoteControl/RemoteControlProvider';
import SessionFeature from './features/session/SessionsRouter';
import Contact from './features/users/Contact';
import RestrictedPage from './features/users/RestrictedPage';
import Theme from "./Theme";
import UserPage from './features/users/UserPage';
import SchedulerFeature from './features/scheduler/SchedulerFeature';
import AdminTools from './features/adminTools/AdminTools';

Initialize(false);

function App() {
	const navigate = useNavigate();
	const location = useLocation();
	const auth = getAuth();

	const [user, setUser] = useState<PremiumUser | null>(null);

	const handleLogout = useCallback(() => {
		signOut(auth).then(() => {
			setUser(() => null);
			navigate('login');
		})
	}, [navigate, auth]);


	const handleLogin = useCallback(() => {
		navigate('login');
	}, [navigate]);

	const updateUser = useCallback(async () => {
		if (auth.currentUser == null) {
			setUser(null);
		}
		else if (!auth.currentUser.emailVerified) {
			setUser(null);
		}
		else {
			const user = auth.currentUser;
			const decodedToken = await user.getIdTokenResult(true);
			const role = getRole(decodedToken.claims);
			const isAdmin = !!decodedToken.claims.admin;
			const userWithRole = { ...user, role: role, isAdmin: isAdmin};
			setUser(() => userWithRole);
		}
	}, [auth.currentUser]);

	const canAccessAllAreas: boolean = user != null &&
		(user.role === Role.Therapist || user.role === Role.Centre);

	useEffect(() => {
		const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
			updateUser();
		});
		return () => unregisterAuthObserver();
	}, [setUser, navigate, auth, updateUser]);

	useEffect(() => {
		if (location.pathname.includes('login') && user != null) {
			navigate("/user/");
		}
	}, [location, user, navigate]);

	return (
		<ThemeProvider theme={Theme}>
			<CssBaseline />
			<UserContext.Provider value={user}>
				<ProcessesProvider>
					<RemoteControlProvider>
						<Box sx={{ display: 'flex' }}>
							<DashboardLayout user={user} onLogout={handleLogout} onLogin={handleLogin} >
								<Content>
									<Routes>
										<Route path="/" element={<LandingPage />} />
										<Route path="/contact" element={<Contact />} />
										<Route path="/login" element={<SignInScreen />} />
										<Route path="/faq" element={<Faq />} />
										<Route path="/privacy_policy" element={<PrivacyPolicy />} />
										<Route path="/buy" element={<PricingPage />} />
										<Route path="/sessions/*" element={!!user ?
											canAccessAllAreas ? <SessionFeature />
												: <RestrictedPage />
											: <Navigate to="/login" />} />
										<Route path="/scheduler/*" element={!!user ?
											canAccessAllAreas ? <SchedulerFeature />
												: <RestrictedPage />
											: <Navigate to="/login" />} />
										<Route path="/remotecontrol/*" element={!!user ?
											canAccessAllAreas ? <RemoteControl />
												: <RestrictedPage />
											: <Navigate to="/login" />} />
										<Route path="/user/*" element={!!user ?
											<UserPage user={user}
												onLicenseUpdated={updateUser}
												onLogout={handleLogout} />
											: <Navigate to="/login" />} />
										<Route path="/user/admin" element={!!user?.isAdmin ?
											<AdminTools />
											: <Navigate to="/login" />} />
									</Routes>
								</Content>
							</DashboardLayout>
						</Box>
					</RemoteControlProvider>
				</ProcessesProvider>
			</UserContext.Provider >
		</ThemeProvider>
	)
}

export default App;
