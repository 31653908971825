import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import { AvatarCustom } from '../users/Avatar';
import { translate } from '../../text/translator';
import { CentreInvite, inviteStatusToText } from './invite';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

interface ReceivedInviteProps {
    invitation: CentreInvite;
    icon: ReactElement;
    onAccept: any;
    onCancel: any;
}

export default function ReceivedCentreInvite(props: ReceivedInviteProps) {
    const button =
        <>
            {props.invitation.status === "pending" &&
                <IconButton
                    aria-label="accept"
                    color="secondary"
                    onClick={props.onAccept}>
                    <CheckIcon />
                </IconButton>
            }
            <IconButton
                aria-label="cancel"
                color="secondary"
                onClick={props.onCancel}>
                <CloseIcon />
            </IconButton>
        </>

    return (
        <ListItem
            divider
            secondaryAction={button}>
            <ListItemAvatar>
                <AvatarCustom id={props.invitation.centreID}>
                    <LocalHospitalIcon style={{ margin: "auto" }} color="inherit" />
                </AvatarCustom>
            </ListItemAvatar>
            <ListItemText primary={props.invitation.centreEmail}
                secondary={translate(`[center_invitation_status] ${inviteStatusToText(props.invitation.status)}`)}
                sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }} />
        </ListItem>
    )
}
