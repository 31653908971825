import { Box, useMediaQuery, useTheme } from "@mui/material";
import ProcessPill from "../../components/atoms/ProcessPill";
import { translate } from "../../text/translator";
import { ModifiersText } from "./Modifiers";
import { SessionTagBig } from "./SessionTag";

export interface TitleProps {
	index: string
	processID: string;
	modifiers: any;
	userID: string;
	timestamp: string;
	sessionTag: string;
}

function SessionHeader(props: TitleProps) {

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<>
			{isMobile &&
				<Box width="100%" sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px', flexDirection: 'column' }}>
					<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '20px', flexDirection: 'column' }}>
						<Box width="100%" sx={{ display: 'flex', alignItems: 'flex-start', gap: '20px', flexDirection: 'row', justifyContent: 'space-between' }}>
							<ProcessPill processID={props.processID} />
							<SessionTagBig name={props.sessionTag} />
						</Box>
						<Box>
							<strong>{translate("[session_date]")}</strong> {props.timestamp}
						</Box>
						<Box>
							<strong>{translate("[session_modifiers]")}</strong>
							<ModifiersText modifiers={props.modifiers} processID={props.processID} />
						</Box>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px', flexDirection: 'column' }}>
					</Box>
				</Box>}

			{!isMobile &&
				<Box width="100%" sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px', flexDirection: 'row' }}>
					<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '20px', flexDirection: 'row' }}>
						<ProcessPill processID={props.processID} />
						<Box>
							<strong>{translate("[session_date]")}</strong>
							<p>{props.timestamp}</p>
						</Box>
						<Box>
							<strong>{translate("[session_modifiers]")}</strong>
							<ModifiersText modifiers={props.modifiers} processID={props.processID} />
						</Box>
					</Box>
					<SessionTagBig name={props.sessionTag} />
				</Box>}
		</>
	)
}

export default SessionHeader;
