import { Box, SvgIcon, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import brainVR from "../../media/landing/brain_vr.png";
import { WhatDoesItWorkData, whatDoesItWorkData, whatDoesItWorkTitle } from "./whatDoesItWorkData";

interface WhatDoesItWorkProps {
	data: WhatDoesItWorkData;
	color: string;
}

function WhatDoesItWorkCard(props: WhatDoesItWorkProps) {
	const data = props.data;
	return (
		<Box p={2} textAlign="center">

			<SvgIcon component={data.icon}
				color="primary"
				viewBox="0 0 64 64"
				fontSize="large"
				style={{ color: props.color }} />
			<Typography variant="h5">
				{data.title}
			</Typography>
			<Typography variant="body1">
				{data.description}
			</Typography>
		</Box>
	);
}

function AdvantagetsGraph() {

	const data = whatDoesItWorkData.chartData;
	const theme = useTheme();

	const RADIAN = Math.PI / 180;
	const labelLateralidad = ({ cx, cy, midAngle, innerRadius, outerRadius, value, payload }: any) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
		return (
			<Typography component="text"
				variant="subtitle1"
				style={{ fill: theme.palette.primary.contrastText }}
				x={x} y={y} textAnchor="middle"
				dominantBaseline="central">
				{payload.title}
			</Typography>
		);
	};

	const pieColors = [theme.palette.primary.main, theme.palette.primary.light, theme.palette.primary.dark];

	return (
		<Box position="relative" width="100%" height="100%">
			<img
				src={brainVR}
				alt="Brain VR"
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					width: '50%',
					aspectRatio: '1/1',
					transform: 'translate(-50%, -50%)',
					zIndex: -1
				}}
			/>
			<ResponsiveContainer width="100%" height="100%">
				<PieChart>
					<Pie
						data={data} dataKey="value" cx="50%" cy="50%" innerRadius="50%" outerRadius="100%"
						labelLine={false}
						label={labelLateralidad}>
						{data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
						))}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		</Box>
	);
}

function WhatDoesItWork() {

	const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

	return (
		<Box my={10}>
			<Typography variant="h2" gutterBottom>
				{whatDoesItWorkTitle}
			</Typography>

			<Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} alignItems="center" >
				<Box display="flex" flexDirection="column" flexShrink={2} >
					<WhatDoesItWorkCard data={whatDoesItWorkData.items[0]} color="primary" />
					<WhatDoesItWorkCard data={whatDoesItWorkData.items[1]} color="primary" />
				</Box>
				<Box flexShrink={1.5} width="100%" maxWidth={600} style={{ aspectRatio: "1 / 1" }} >
					<AdvantagetsGraph />
				</Box>
				<Box display="flex" flexDirection="column" flexShrink={2}>
					<WhatDoesItWorkCard data={whatDoesItWorkData.items[2]} color="primary" />
					<WhatDoesItWorkCard data={whatDoesItWorkData.items[3]} color="primary" />
				</Box>
			</Box>

			<Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} marginTop={10} alignItems="center" gap={2} >
				<Box width="100%" maxWidth={600}>
					<iframe
						style={{ width: "100%", aspectRatio: "16 / 9" }}
						src={whatDoesItWorkData.video}
						title="Video ReCognition"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					/>
				</Box>
				<Box display="flex" flexDirection="column" textAlign="center" alignItems="center" justifyContent="center" gap={2} >
					<Typography variant="subtitle1">
						{whatDoesItWorkData.paragraph1}
					</Typography>
					<Typography variant="subtitle1">
						{whatDoesItWorkData.paragraph2}
					</Typography>
				</Box>
			</Box >
		</Box>
	)
}

export default WhatDoesItWork;
