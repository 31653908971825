import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { AvatarCustom } from '../users/Avatar';
import { ReactComponent as PatientIcon } from '../../media/independentPatient.svg';
import SvgIcon from '@mui/icons-material/Delete';
import { translate } from '../../text/translator';
import { PatientInvite, inviteStatusToText } from '../center/invite';

interface SentPatientInviteProps {
	invitation: PatientInvite;
	onDelete: any;
}

export default function SendPatientInvite(props: SentPatientInviteProps) {

	const deleteButton =
		<IconButton edge="end"
			aria-label="delete"
			color="secondary"
			onClick={props.onDelete}>
			<DeleteIcon />
		</IconButton>

	return (
		<ListItem
			divider
			secondaryAction={deleteButton}>
			<ListItemButton>
				<ListItemAvatar>
					<AvatarCustom id={props.invitation.patientID}>
						<SvgIcon component={PatientIcon}
							color="inherit"
							viewBox="0 0 64 64"
							style={{ margin: "auto", color: "inherit" }} />
					</AvatarCustom>
				</ListItemAvatar>
				<ListItemText primary={props.invitation.patientEmail} 
				secondary={translate(`[patient_invitation_status] ${inviteStatusToText(props.invitation.status)}`)}
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
					}} />
			</ListItemButton>
		</ListItem>
	)
}
