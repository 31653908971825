import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useState } from "react";
import { ProcessesContext } from "../../../contexts/Processes.context";
import { Process } from "../../../data/Process";
import ProcessCard from "./ProcessCard";
import AlertDialog from '../../../components/atoms/Popup';
import { translate } from '../../../text/translator';
import ProcessModifiers from './ProcessModifiers';
import { Button, CircularProgress, IconButton, Paper } from '@mui/material';
import ProcessPill from '../../../components/atoms/ProcessPill';
import { ModifiedProcess } from '../../../contexts/RemoteControlState';
import BackspaceIcon from '@mui/icons-material/Backspace';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Patient } from '../../../data/Patient';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { UserContext } from '../../../contexts/User.context';
import { loadSchedule } from '../../scheduler/SchedulerFeature';

interface ProcessesProps {
	onProcessesSelected: (processes: ModifiedProcess[]) => any;
	patient: Patient | null;
	onProcessesUpdated?: () => any;
	queueOnly?: boolean;
	minVersion?: number;
	startQueueText?: string;
	defaultQueue?: ModifiedProcess[];
	disabled?: boolean;
}

function Processes(props: ProcessesProps) {
	const { processes } = useContext(ProcessesContext);
	const user = useContext(UserContext);
	const [modifiersModalOpened, setModifiersModalOpened] = useState(false);

	const [processSelected, setProcessSelected] = useState<ModifiedProcess | null>(null);
	const [modifiers, setModifiers] = useState<any>({});

	const [processesQueue, setProcessesQueue] = useState<ModifiedProcess[]>(props.defaultQueue ?? []);
	const [isQueueMode, setIsQueueMode] = useState<boolean>(!!props.queueOnly);
	const [processesQueueIndex, setProcessesQueueIndex] = useState<number>(0);

	const validProcesses = processes.filter((process) => {
		return !props.minVersion || process.minVersion <= props.minVersion;
	});

	function handleProcessSelected(process: Process) {
		setProcessSelected({ process: process, modifiers: undefined });
		setModifiersModalOpened(true);
	}

	function handleProcessEdit(index: number) {
		setProcessesQueueIndex(index);
		setProcessSelected(processesQueue[index]);
		setModifiersModalOpened(true);
	}

	function handleStart() {
		props.onProcessesSelected(processesQueue);
	}

	function handleCloseModifiersModal() {
		setModifiersModalOpened(false);
	}

	const handleModifiersUpdated = (modifiers: any) => {
		setModifiers(modifiers);
	}

	const handleModifiersAccepted = () => {
		if (!processSelected) {
			return;
		}

		if (isQueueMode) {
			setProcessesQueue((state) => [...state, {
				process: processSelected.process!,
				modifiers: modifiers
			}]);
			props.onProcessesUpdated && props.onProcessesUpdated();
		}
		else {
			props.onProcessesSelected([{
				process: processSelected.process!,
				modifiers: modifiers
			}]);
		}

		setModifiersModalOpened(false);
	}

	function handleModifiersEdited(index: number) {
		setProcessesQueue((state) => {
			state[index].modifiers = modifiers;
			return [...state];
		});
		setModifiersModalOpened(false);
		props.onProcessesUpdated && props.onProcessesUpdated();
	}

	function handleModifiersDeleted(index: number) {
		setProcessesQueue((state) => {
			state.splice(index, 1);
			return [...state];
		});
		setModifiersModalOpened(false);
	}

	const handleRemoveFromQueue = () => {
		if (processesQueue.length > 0) {
			setProcessesQueue(processesQueue.slice(0, -1));
			props.onProcessesUpdated && props.onProcessesUpdated();
		}
	}

	const handleQueueMode = async () => {
		setIsQueueMode(true);
		const schedule = await loadSchedule(user, props.patient, processes);
		setProcessesQueue(schedule);
	}

	const handleNoQueueMode = () => {
		setIsQueueMode(false);
		setProcessesQueue([]);
	}

	useEffect(() => {
		setProcessesQueue(props.defaultQueue ?? []);
	}, [props.defaultQueue]);

	return (
		<>
			<Box marginY={2}
				gap={2}
				style={{ width: "100%", justifyContent: 'right', display: 'flex', alignItems: 'space-between' }}>

				{isQueueMode &&
					<Paper style={{width :"100%"}}>
					<Box width="100%" display="flex" justifyContent="left" alignItems="left" flexWrap="wrap" margin={1}>
						{
							processesQueue.map((process, index) => (
								<Button key={index} onClick={() => { handleProcessEdit(index) }}
									color="primary"
									style={{ padding: 0, minWidth: 0, margin: 1 }}>
									<ProcessPill key={index}
										processID={process.process.id}
										tiny={index < processesQueue.length - 1} />
								</Button>
							))}
						<IconButton onClick={handleRemoveFromQueue} color="primary"
							disabled={processesQueue.length === 0}>
							<BackspaceIcon />
						</IconButton>
					</Box>
					</Paper>
				}

				{((isQueueMode && processesQueue.length > 0) || props.queueOnly) &&
					<Button onClick={handleStart} startIcon={<PlayArrowIcon />}
						variant="contained"
						disabled={props.disabled ?? false}>
						{translate(props.startQueueText ?? "[processespage_startqueue]")}
					</Button>
				}

				{isQueueMode && processesQueue.length === 0 && !props.queueOnly &&
					<Button onClick={handleNoQueueMode}>
						{translate("[processespage_noqueuemode]")}
					</Button>
				}

				{!isQueueMode && !props.queueOnly &&
					<Button onClick={handleQueueMode}
						startIcon={<AddIcon />}
						variant="outlined">
						{translate("[processespage_queuemode]")}
					</Button>
				}
			</Box>


			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={{ xs: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
					{validProcesses.map((process, index) => (
						<Grid xs={2} sm={4} md={4} key={index}>
							<ProcessCard
								process={process}>
								<Button size="small" onClick={() => handleProcessSelected(process)}
									startIcon={isQueueMode ? <AddIcon /> : <PlayArrowIcon />}
									variant={isQueueMode ? "outlined" : "outlined"}>
									{translate(isQueueMode ? "[processespage_enqeue]" : "[processespage_start]")}
								</Button>
							</ProcessCard>
						</Grid>
					))}
				</Grid>
			</Box>

			<AlertDialog
				open={!!modifiersModalOpened}
				onCancel={handleCloseModifiersModal}
				title={translate("[remotecontrol_modifiers]")}
				header={
					<Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
						<ProcessPill processID={processSelected?.process.id!} />

						{!processSelected?.modifiers &&
							<Button size="small"
								onClick={handleModifiersAccepted}
								startIcon={isQueueMode ? <AddIcon /> : <PlayArrowIcon />}
								variant="contained">
								{translate(isQueueMode ? "[processespage_enqeue]" : "[processespage_start]")}
							</Button>
						}

						{!!processSelected?.modifiers &&
							<Box display="flex" flexDirection="row" gap={2}>
								<Button size="small"
									onClick={() => handleModifiersDeleted(processesQueueIndex)}
									startIcon={<DeleteIcon />}
									variant={"outlined"}>
									{translate("[processespage_delete]")}
								</Button>

								<Button size="small"
									onClick={() => handleModifiersEdited(processesQueueIndex)}
									startIcon={<SaveAsIcon />}
									variant={"contained"}>
									{translate("[processespage_edit]")}
								</Button>
							</Box>
						}

					</Box>
				}>
				{!!processSelected ? (
					<ProcessModifiers
						modifierDefinitions={processSelected.process.modifiers}
						defaultModifiers={processSelected.modifiers}
						onModifiersUpdated={handleModifiersUpdated}
						proccessID={processSelected.process.id}
						patient={props.patient} />
				) : (
					<CircularProgress />
				)}
			</AlertDialog>
		</>
	)
}


export default Processes;