import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { translate } from "../../text/translator";
import ContactForm from "../landing/ContactForm";
import { pricingData } from "./pricingData";
import PricingTable from './PricingTable';

interface PricingPageProps {
	hideTitle?: boolean,
	hideContactForm?: boolean,
}

function PricingPage(props: PricingPageProps) {

	return (
		<>
			{!props.hideTitle &&
				<Typography variant="h3" gutterBottom>
					{translate("[pricing_buylicense]")}
				</Typography>
			}

			<Typography variant="subtitle1" gutterBottom component="div">
				<ReactMarkdown>
					{translate("[pricing_subtitle]")}
				</ReactMarkdown>
			</Typography>

			<PricingTable />

			<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
				<Box gap={2} maxWidth={500} margin={2}>

					<Typography variant="h4" textAlign="center">
						{pricingData.custom.title}
					</Typography>
					<Typography variant="body1" component="div">
						<ReactMarkdown>
							{pricingData.custom.description}
						</ReactMarkdown>
					</Typography>
					<List
						sx={{
							pl: 0,
							'& .MuiListItem-root': {
								display: 'flex',
								alignItems: 'center',
							},
						}}>
						{pricingData.custom.features.map((feature, index) => (
							<ListItem key={index}>
								<ListItemIcon>
									<CheckCircleIcon color="secondary" />
								</ListItemIcon>
								<ReactMarkdown>
									{feature}
								</ReactMarkdown>
							</ListItem>
						))}
					</List>
					{!props.hideContactForm &&
						<ContactForm />}
				</Box>
			</Box>

		</>
	);
}

export default PricingPage;
