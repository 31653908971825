import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { getProcessTitle } from '../../data/Process';

import { ReactComponent as IconAttention } from '../../media/attention.svg';
import { ReactComponent as IconBirds } from '../../media/birds.svg';
import { ReactComponent as IconComprehension } from '../../media/comprehension.svg';
import { ReactComponent as IconGonogo } from '../../media/gonogo.svg';
import { ReactComponent as IconGrill } from '../../media/grill.svg';
import { ReactComponent as IconInterference } from '../../media/interference.svg';
import { ReactComponent as IconMemory } from '../../media/memory.svg';
import { ReactComponent as IconProcessingSpeed } from '../../media/processingspeed.svg';
import { ReactComponent as FruitsIcon } from '../../media/fruits.svg';
import { ReactComponent as DishwasherIcon } from '../../media/dishwasher.svg';
import { ReactComponent as CleanSurfaceIcon } from '../../media/soap.svg';
import { ReactComponent as DefaultIcon } from '../../media/default.svg';


interface ProcessPillProps {
	processID: string;
	tiny?: boolean;
}

export const iconMap: { [key: string]: any } = {
	"attention": IconAttention,
	"comprehension": IconComprehension,
	"memory": IconMemory,
	"processingSpeed": IconProcessingSpeed,
	"birds": IconBirds,
	"gonogo": IconGonogo,
	"grill": IconGrill,
	"interference": IconInterference,
	"fruits": FruitsIcon,
	"dishwasher": DishwasherIcon,
	"cleanSurface": CleanSurfaceIcon,
};

export const colorMap: { [key: string]: string } = {
	"attention": "#3166b0",
	"comprehension": "#59b031",
	"memory": "#b03199",
	"processingSpeed": "#b07931",
	"birds": "#31aeb0",
	"gonogo": "#b03131",
	"grill": "#6631b0",
	"interference": "#b0ae31",
	"fruits": "#ff9800",
	"dishwasher": "#63C7B2",
	"cleanSurface": "#2D57BC",
};

export const ProcessPill: React.FC<ProcessPillProps> = (props) => {

	const title = getProcessTitle(props.processID);

	const color = colorMap[props.processID!];
	const icon = iconMap[props.processID!] || DefaultIcon;

	return (
		<Box
			padding="8px"
			borderRadius="8px"
			display="inline-flex"
			alignItems="left"
			justifyContent="left"
			textAlign="left"
			minWidth="0"
			gap="8px"
			bgcolor={`${color}50`}
		>
			<SvgIcon component={icon}
				color="primary"
				viewBox="0 0 64 64"
				style={{ color: color }} />

			{!props.tiny &&
				<Box component="div" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" minWidth="0">
					<Typography variant="body1" color={color} >
						{title}
					</Typography>
				</Box>
			}
		</Box>
	);
};

export default ProcessPill;
