import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { RemoteClient } from '../../../data/RemoteClient';

import Quest2Image from '../../../media/quest2.png';
import QuestProImage from '../../../media/questpro.png';
import { translate } from '../../../text/translator';
import theme from '../../../Theme';


interface RemoteConnectionCardProps {
	client: RemoteClient;
	latestVersion: number;
	onSelected: (p: RemoteClient) => void;
}

const deviceImageMap: { [key: string]: string } = {
	"Quest2": Quest2Image,
	"QuestPro": QuestProImage,
};

export default function RemoteConnectionCard(props: RemoteConnectionCardProps) {

	const client: RemoteClient = props.client;
	const deviceId = client.deviceId;
	const deviceType = client.deviceType;
	const version = client.version;

	const isOutdated = client.version < props.latestVersion!;

	const errorColor = theme.palette.error.main;
	const handleSelect = () => {
		props.onSelected(props.client);
	}

	const connecting = client.ices.length === 0;
	const deviceImage = deviceImageMap[deviceType] || Quest2Image;

	return (
		<Card>
			<CardContent>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<img src={deviceImage} alt={deviceType} />
					<Typography variant="h5" component="div" style={{ textAlign: 'center' }}>
						{deviceId}
					</Typography>
					<Typography component="div" style={{ textAlign: 'center' }}>
						{isOutdated && <span style={{ color: errorColor }}>{translate("[remoteconnectioncard_outdated]")} v{version}</span>}
						{!isOutdated && <span>v{version} </span>}
						<br/>
						{deviceType}
					</Typography>
				</div>

			</CardContent>
			<CardActions>
				{connecting && <CircularProgress size={10} />}
				<Button size="small" variant="outlined" onClick={handleSelect} disabled={connecting} >
					{connecting ? translate("[remoteconnectioncard_searching]") : translate("[remoteconnectioncard_connect]")}
				</Button>
			</CardActions>
		</Card>
	);
}