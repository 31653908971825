import Avatar from "./Avatar";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


export interface PatientProps {
	initials: string;
	name: string;
	deleteable: boolean;
	index: string;
	onSelect: any;
	onDelete: any;
}

export default function PatientRow(props: PatientProps) {

	const deleteButton =
		props.deleteable &&
		<IconButton edge="end"
			aria-label="delete"
			color="secondary"
			onClick={props.onDelete}>
			<DeleteIcon />
		</IconButton>
		
	return (
		<ListItem
			divider
			secondaryAction={deleteButton}>
			<ListItemButton onClick={props.onSelect} >
				<ListItemAvatar>
					<Avatar name={props.initials} id={props.name} />
				</ListItemAvatar>
				<ListItemText primary={props.index} secondary={props.name}
					sx={{
					overflow: "hidden",
					textOverflow: "ellipsis",
				}} />
			</ListItemButton>
		</ListItem>
	)
}
