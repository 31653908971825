import { Container, Typography } from '@mui/material';
import { translate } from '../../text/translator';
import Advantages from './Advantages';
import Collaborators from './Collaborators';
import ContactForm from './ContactForm';
import Introduction from './Introduction';
import Newsletter from './Newsletter';
import OurTeam from './OurTeam';
import Research from './Research';
import UserReviews from './UserReviews';
import WaveSection from './WaveSection';
import WhatDoesItWork from './WhatDoesItWork';
import PricingPage from '../buying/PricingPage';

function LandingPage() {

	return (
		<Container>

			<WaveSection
				marginTop={200}
				marginBottom={100}
				isTop>
				<Introduction />
			</WaveSection>

			<WhatDoesItWork />

			<WaveSection
				marginTop={70}
				marginBottom={100}>
				<Advantages />
			</WaveSection>

			<Research />
			<OurTeam />

			<UserReviews />

			<Collaborators />

			<Typography variant="h2" gutterBottom>
				{translate("[pricing_buylicense]")}
			</Typography>
			<PricingPage hideTitle hideContactForm />

			<Typography variant="h2" gutterBottom>
				{translate("[contact_title]")}
			</Typography>
			<ContactForm />
			<br />
			<Newsletter />
		</Container>
	);
}

export default LandingPage;
