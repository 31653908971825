import { Box, Typography, useTheme } from "@mui/material";
import { Pie, PieChart, ResponsiveContainer } from "recharts";
import { translate } from "../../../text/translator";
import { DishwasherData, ItemCollectionData } from "../../DishwasherData";

interface AverageCardData {
	data: DishwasherData;
}

interface LabelData {
	cx: number;
	cy: number;
	midAngle: number;
	innerRadius: number;
	outerRadius: number;
	index: number;
	value: number;
	fill: string;
}

function extractErrors(data: ItemCollectionData) {
	const notPlaced = data.missplaced;
	const wellPlaced = data.items.filter(i => i.wellOriented).length;
	const badPlaced = data.items.length - wellPlaced;
	return { notPlaced, wellPlaced, badPlaced };
}

function AverageDishwasherCard(props: AverageCardData) {
	const data: DishwasherData = props.data;

	const mugs = extractErrors(data.mugs);
	const forks = extractErrors(data.forks);
	const dishes = extractErrors(data.dishes);

	const total = mugs.notPlaced + forks.notPlaced + dishes.notPlaced
		+ data.mugs.items.length + data.forks.items.length + data.dishes.items.length;

	const wellPlaced = mugs.wellPlaced + forks.wellPlaced + dishes.wellPlaced;
	const badPlaced = mugs.badPlaced + forks.badPlaced + dishes.badPlaced;
	const notPlaced = (mugs.notPlaced + forks.notPlaced + dishes.notPlaced);

	const colors = useTheme().palette;

	const pieData = [
		{ name: '[graphs_dishwasher_correct]', value: wellPlaced, fill: colors.primary.main },
		{ name: '[graphs_dishwasher_notWellOriented]', value: badPlaced, fill: colors.secondary.main },
		{ name: '[graphs_dishwasher_missplaced]', value: notPlaced, fill: colors.error.main },
	];

	const renderCustomizedLabel = (data: LabelData) => {
		const RADIAN = Math.PI / 180;
		const radius = 25 + data.innerRadius + (data.outerRadius - data.innerRadius);
		const x = data.cx + radius * Math.cos(-data.midAngle * RADIAN);
		const y = data.cy + radius * Math.sin(-data.midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				textAnchor={x > data.cx ? "start" : "end"}
				dominantBaseline="central"
			>
				{data.value > 0 && `${data.value} ${translate(pieData[data.index].name)}`}
			</text>
		);
	};

	return (<>

		<Typography><strong>{translate("[graphs_finalresult]")}</strong></Typography>
		<br />
		<Box>
			<Typography>{translate("[graphs_dishwasher_total_time]")}</Typography>
			<Typography variant="h4">
				{(data.totalTime).toFixed(1)} {translate("[units_seconds]")}
			</Typography>
		</Box>

		<Box>
			<Typography>{translate("[graphs_dishwasher_error]")}</Typography>
			<Typography variant="h4">
				{(100 * notPlaced / total).toFixed(2)}%
			</Typography>
		</Box>

		<br />

		<ResponsiveContainer height={200}>
			<PieChart >
				<Pie
					data={pieData}
					cx="50%"
					cy="50%"
					labelLine={false}
					outerRadius={50}
					dataKey="value"
					label={renderCustomizedLabel}
				/>
			</PieChart>
		</ResponsiveContainer>
	</>);
}

export default AverageDishwasherCard;