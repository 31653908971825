import userInVR from "../../media/landing/userInVR.png";
import userInRR from "../../media/landing/userInRR.png";

export const introductionData =
{
	"title": "Re-Cognition",
	"subtitle": "Realidad Virtual aplicada a la rehabilitación de lesiones neurológicas",
	"paragraph": "Herramienta de Realidad Virtual desarrollada para mejorar los procesos cognitivos, motores y funcionales de las personas que han sufrido un Daño Cerebral Adquirido, potenciar los mismos procesos en niños con problemas de Neurodesarrollo y paliar el avance de los problemas Neurodegenerativos.",
	"image1": userInVR,
	"image2": userInRR,
	"twitter": "https://twitter.com/ReCognitionRV",
	"instagram": "https://www.instagram.com/recognitionrv/",
	"facebook": "https://www.facebook.com/ReCognitionRV",
	"aplications": [
		{
			"title": "Daño Cerebral Adquirido",
		},
		{
			"title": "Demencias",
		},
		{
			"title": "Neurodesarrollo",
		},
		{
			"title": "Investigación",
		},
	],
}